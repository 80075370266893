import React from 'react';
import './Field.scss';

/**
 * Field component
 * 
 * @param {object} props
 * @returns {JSX Element}
 */
function Field (props) {

  const { title, subtitle, additionalClassName = '' } = props;

  
  return (
    <div className={`field field-${additionalClassName}`}>
      <div className="field__title">
        <span className="field__title-main">{title}</span>
        { subtitle && <span className="field__title-sub">{subtitle}</span> }
      </div>
      {props.children}
    </div>
  );
}

export default Field;