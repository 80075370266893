export const donationOptions = [
  {
    amount: "1.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=K26oAFSAh"
  },{
    amount: "2.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=jRlwvzDNB"
  },{
    amount: "3.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=pSrJrvRMK"
  },{
    amount: "5.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=qeJ8HmPhM"
  },{
    amount: "10.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=UX4pkVBBF"
  },{
    amount: "25.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=iG0a05vc5"
  },{
    amount: "50.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=eaNd32p6V"
  },{
    amount: "100.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=v89NCNKUn"
  },{
    amount: "200.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=z3uvrs4AK"
  }
]


export const subscriptionOptions = [
  {
    amount: "1.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=JcD7aosEO"
  },{
    amount: "2.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=FnNeWcPxJ"
  },{
    amount: "3.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=qlHXM8Zoj"
  },{
    amount: "4.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=y3FaDdqKj"
  },{
    amount: "5.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=eZ5Z1euZU"
  },{
    amount: "7.500 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=1xA4YOjCD"
  },{
    amount: "10.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=fIA3viWWO"
  },{
    amount: "15.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=IGMaxiOga"
  },{
    amount: "25.000 Kr",
    link: "https://securepay.borgun.is/SecurePay/Ticket.aspx?ticket=QSdKUC8dA"
  }
]