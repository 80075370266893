import React from 'react';
import './RadioButtonValue.scss';
import { useTranslation } from 'react-i18next';


/**
 * RadioButtonValu component
 * 
 * @param {object} props 
 */
function RadioButtonValue (props) {

  const { value } = props;
  const { t } = useTranslation();

  
  return Object.entries(value).map(entry => {
    const [optionIndex, optionData] = entry;

    return optionData.checked && 
          <span className="radio-button-value" key={optionIndex}>{t(`${optionData.name}`)}</span>
    
  })
}

export default RadioButtonValue;