import React from 'react';
import DialogBox from './DialogBox';
import ConfirmButton from './ConfirmButton';
import CancelButton from './CancelButton';
import { useTranslation } from "react-i18next";
import './ModalInnerDialog.scss';

/**
 * ModalInnerDialog component
 * 
 * @param {object} param - Destructured props object.
 * @param {string} param.handle - Type and use of modal. Used for translations.
 * @param {string} param.image - image type that renders specific icon inside DialogBox
 * @param {function} param.close - Used for closing modal that renders DialogBox
 * @param {function} param.confirm - Used for confirming action on parent modal. If not present, button omitted.
 * @param {boolean} param.textAsNote
 * @param {boolean} param.showBtnLoader
 * 
 * @returns {JSX Element}
 * 
 */
function ModalInnerDialog({handle, image, close, confirm = false, textAsNote = false, showBtnLoader}) {

  const { t } = useTranslation();

  const renderImage = ( image ) => {
    switch( image ) {
      case 'exclamation': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
              <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                  <circle cx="20" cy="20" r="20" stroke="#777"/>
                  <path stroke="#777" d="M20 9.5v16.876"/>
                  <path fill="#777" d="M19 29h2v2h-2z"/>
              </g>
          </svg>
        );
      case 'question': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
              <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                  <circle cx="20" cy="20" r="20" stroke="#777"/>
                  <path fill="#777" fillRule="nonzero" d="M19.51 24.35c.008-1.005.095-1.705.261-2.1.166-.396.617-.92 1.352-1.572.854-.859 1.476-1.578 1.867-2.158.392-.579.587-1.314.587-2.204 0-.982-.269-1.742-.806-2.279-.538-.537-1.304-.805-2.3-.805-.933 0-1.684.262-2.253.788-.569.526-.857 1.237-.865 2.135h-1.328L16 16.086c-.024-1.266.387-2.264 1.233-2.993.846-.729 1.925-1.093 3.237-1.093 1.415 0 2.523.387 3.326 1.162.802.775 1.203 1.823 1.203 3.143 0 1.036-.263 1.954-.788 2.756-.526.802-1.271 1.64-2.235 2.515-.49.407-.789.787-.896 1.14-.106.353-.16.897-.16 1.634h-1.41zM21.111 29h-1.648v-1.773h1.648V29z"/>
              </g>
          </svg>
        );
      case 'success': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42">
              <g fill="none" fillRule="evenodd" stroke="#777">
                  <path d="M40.3 15.7c.5 1.7.7 3.4.7 5.3 0 11-9 20-20 20S1 32 1 21 10 1 21 1c3.9 0 7.5 1.1 10.5 3"/>
                  <path strokeLinecap="square" d="M13 17l8 8L43 3"/>
              </g>
          </svg>
        );
      case 'fee': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="43" viewBox="0 0 64 43">
              <g fill="none" fillRule="evenodd" stroke="#777">
                  <path d="m25.952 29.662 11.844 11.844 25.004-32.9" strokeLinecap="square"/>
                  <path d="m36.28 13.498h-35.28"/>
                  <g strokeLinecap="square">
                      <path d="m16.75 34.39h-12.6c-1.74 0-3.642-1.41-3.642-3.15v-23.31c0-1.74 1.902-3.15 3.642-3.15h28.98c1.74 0 3.369 1.41 3.369 3.15v12.6m-26.988-19.53v7.56m18.007-7.56v7.56"/>
                      <path d="m13.507 17v12.6m2.793-10.357c-1.61-.723-5.88-1.049-5.88 1.537 0 3.225 5.88 1.97 5.88 4.815s-3.723 2.632-6.3 1.707"/>
                  </g>
              </g>
          </svg>
        );
      case 'card': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="39" viewBox="0 0 66 39">
              <g fill="none" fillRule="evenodd" stroke="#777" strokeLinecap="square">
                  <path d="m18.108 29.504h-14.908c-1.453 0-2.7-1.452-2.7-2.868v-23.072c0-1.416 1.247-3.06 2.7-3.06h34.606c1.453 0 2.694 1.644 2.694 3.06v15.381"/>
                  <path d="m27.952 25.662 11.844 11.844 25.004-32.9m-63.3 9.894h39m-34 8h9m-14-15h39"/>
              </g>
          </svg>
        );
      default: 
        return null;
    }
  }

  return (
    <div className="modal-inner-dialog">
      <DialogBox 
        image={renderImage(image)}
        close={close}
        title={t('modals.'+handle+'.title')}
        text={t('modals.'+handle+'.text')}
        textAsNote={textAsNote}
        renderButtons={() => {
          return (
            <>
              {confirm ? (
                <>
                  <ConfirmButton 
                    className="confirm-button" 
                    onClick={confirm}
                    showLoader={showBtnLoader}
                  >
                    {t('modals.'+handle+'.mainButtonText')}
                  </ConfirmButton>
                  <CancelButton onClick={close} />
                </>
              ) : (
                <ConfirmButton 
                  className="confirm-button" 
                  onClick={close}
                  showLoader={showBtnLoader}
                >
                  {t('modals.'+handle+'.mainButtonText')}
                </ConfirmButton>
              )}
            </>
          )
        }}
      />
    </div>
  );
}

export default ModalInnerDialog;