import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppState } from '../store';
import NavigationItem from './NavigationItem';
//Icons 
import { ReactComponent as ProfileIcon } from '../components/images/profile-icon.svg';
import { ReactComponent as PartyIcon } from '../components/images/party-icon.svg';
import { ReactComponent as SettingsIcon } from '../components/images/settings-icon.svg';
//Style
import './_navigation.scss';
//Translations 
import { useTranslation } from 'react-i18next';

/**
 * Navigation component
 * @returns {JSX Element}
 */
function Navigation() {

  const { pathname } = useLocation()
  const { previousPath } = useAppState()
  const { t } = useTranslation();

  const [ underlinePosition, setUnderlinePosition ] = useState(false)

  /**
   * Create css class from provided path string
   * 
   * @param {string} path 
   * @returns {string}
   */
  const prepareCssClass = path => {
    if(typeof path === 'undefined') return 'profile'
    return path.substring(1)
  }

  useEffect(() => {
    setUnderlinePosition(true)
  }, [])
  

  return (
    <div className='navigation'>
      <NavigationItem className="navigation__item" url='/profile' >
        <ProfileIcon /><span>{t('profile')}</span>
      </NavigationItem>
      <NavigationItem className="navigation__item" url='/party' >
        <PartyIcon /><span>{t('party')}</span>
      </NavigationItem>
      <NavigationItem className="navigation__item" url='/settings' >
        <SettingsIcon /><span>{t('settings')}</span>
      </NavigationItem>
      <div 
        className={`navigation__active-bar previous-active-${prepareCssClass(previousPath)} current-active-${underlinePosition ? prepareCssClass(pathname) : ''} ` }
      ></div>
    </div>
  )
}

export default Navigation;