import React from 'react';
import './ActionButton.scss';


/**
 * ActionButton component
 * 
 * @param {object} param - destructured props object
 * @param {functon} param.onClick - handles button onClick event
 * @param {string} param.buttonText - provides text for action button
 * @param {string} param.externalLink - provides URL for external link 
 * 
 * @returns {JSX Element}
 */
const ActionButton = ({ onClick, buttonText, externalLink }) => {

  return (
    !externalLink ? (
      <button className="action-button" onClick={onClick}>
        {buttonText}
      </button>
    ) : (
      <a className="action-button" href={externalLink} target="_blank" rel="noreferrer">{buttonText}</a>
    )

  )
}

export default ActionButton;