import React from 'react';
import './CheckBoxList.scss';
import { useTranslation } from 'react-i18next';


/**
 * CheckBoxList component
 * 
 * @param {object} props 
 * @returns {JSX Element}
 */
function CheckBoxList (props) {
  const { value } = props;

  const { i18n } = useTranslation();
  let lang = i18n.language;

  return (
    <ul className="checkbox-list">
        {
          Object.entries(value).map(entry => {
            const [ optionIndex, optionData ] = entry;

            return optionData.checked && 
              <li className="checkbox-list__item" key={optionIndex}>
                {lang === 'is' ? optionData.name : optionData.name_en}
              </li>
              
          })
        }     
    </ul>
  )
}

export default CheckBoxList;