import React from 'react';
import Content from './Content';
import Sidebar from './Sidebar';
import Header from './Header';
import Navigation from './Navigation';
import ModalWrapper from './ModalWrapper';
import '../global-styles/_global-style.scss';
import { useAppState } from '../store';

/**
 * Component
 *
 * @param {object} props
 * @return {ReactElement}
 */
function Wrapper(props) {

  const { user } = useAppState();
  const textSize = user.settings.text_size.content.value.selectedOption;

  return (
    <div className={`main ${textSize}-size-text`}>
      <Header />
      <Navigation />
      <Content data={props.data} screen={props.screen} labels={props.labels} />
      <Sidebar />
      <ModalWrapper />
    </div>
  );
}

export default Wrapper;