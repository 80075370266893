import produce from 'immer';

/**
 * Reducer function
 * 
 * @param {object} state 
 * @param {object} action
 * 
 * @returns {object} new state 
 */ 
const myPagesReducer = (state, action) => 
  produce(state, draft => {
    switch (action.type) {
      case 'ADD_USER_DATA':
        Object.entries(action.payload).forEach(([section, sectionData]) => {
          if(section === 'accepted_terms' || section === 'deleted' || section ==='reasons_for_leaving' || section ==='sponsor') {
            draft.user[section] = sectionData
          } else {
            Object.entries(sectionData).forEach(([sectionProp, sectionPropData]) => {
              if( draft.user[section] && draft.user[section][sectionProp]) {
                // if field type is not 'radioButton'
                if(draft.user[section][sectionProp].content.type !== 'radioButton') {
                  // if filed type is single and has 'value_en' prop set both 'value' and 'value_en'
                  if(draft.user[section][sectionProp].content.type === 'single' && sectionPropData.content.value_en) {
                    draft.user[section][sectionProp].content.value = sectionPropData.content.value;
                    draft.user[section][sectionProp].content.value_en = sectionPropData.content.value_en;
                  } else {
                    draft.user[section][sectionProp].content.value = sectionPropData.content.value;
                  }
                } else {
                  draft.user[section][sectionProp].content.value.selectedOption = sectionPropData.content.value;
                }
              }
            })
          }
        })
        break;
      case 'CHANGE_CURRENTLY_EDITING_FIELD':
        draft.currentEditingField[action.payload.fieldToUpdate] = action.payload[action.payload.fieldToUpdate];
        break;
      case 'CHANGE_PROFILE_INFO':
        draft.user.profile[action.payload.fieldName].content.value = action.payload.value;
        break;
      case 'CHANGE_PARTY_INFO':
        draft.user.party[action.payload.fieldName].content.value = action.payload.value;
        break;        
      case 'CHANGE_SETTINGS':
        draft.user.settings[action.payload.fieldName].content.value = action.payload.value;
        break;
      case 'CHANGE_ACCEPT_TERMS':
        draft.user.accepted_terms = action.payload.value;
        break;
      case 'CHANGE_LOGGED_STATUS':
        draft.user.logged_out = action.payload.value;
        break;
      case 'TOGGLE_MODAL':
          draft.openedModal = { name: action.modalName, type: action.modalType || null}
        break;
      case 'TOGGLE_SIDEBAR': 
        draft.openSidebar = draft.openSidebar ? false : true;
        break;
      case 'CLOSE_SIDEBAR': 
        draft.openSidebar = false;
        break;
      case 'SHOW_ERROR': 
        draft.errorPopup = action.payload;
        break;
      case 'SHOW_LOADER': 
        draft.showLoader = action.payload.show;
        break
      case 'SET_PREVIOUS_PATH':
        draft.previousPath = action.payload.path
      // no default
    }
  });
  

export default myPagesReducer;