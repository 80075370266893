import { useTranslation } from "react-i18next";
/**
 * EditRadioButton component
 * 
 * @param {object} param - string 
 * @param {string} param.sectionContent - indicates which object ( from translations ) will be used
 * 
 * @returns Array => Array contains strings 
 */
export default function SectionLabels(sectionContent) {

  // destructured translation function t which we use to translate content
  const { t } = useTranslation()

  let sectionTranslationsArray =  [];

  t(sectionContent,{returnObjects: true})
  .forEach(item => {
    let itemValue = Object.values(item);
    sectionTranslationsArray.push(itemValue[0])
  });

  return sectionTranslationsArray;
}