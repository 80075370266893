import React, { useState } from 'react';
import Text from './Text';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import { useAppState, useAppApi } from '../store';
import './EditText.scss';

// import { useAppDispatch } from '../store';



/**
 * EditText component
 * 
 * @param {object} param - destructured props object
 * @param {string} param.title - field title
 * @param {object} param.content - field content
 * @param {boolean} param.shouldEdit - used to determine whether or not editable content shoud be shown
 * @param {function} param.hideEditableContent - hides editable content
 * @param {function} param.onEdit - handles field value dispatch
 * @param {function} param.changeEditedFieldStatus - handles editable field status dispatch
 * @param {string} param.fieldName - field name
 * @param {string} param.message - edit message
  * 
 * @returns {JSX Element}
 */
function EditText ({ title, content, shouldEdit, hideEditableContent, onEdit, changeEditedFieldStatus, fieldName, message, updateMiddleware }) {

  const [ showError, setShowError ] = useState(false);
  const { currentEditingField } = useAppState();
  const { showErrorPopup, hideErrorPopup } = useAppApi();
  const { edited, initialValue } = currentEditingField;
  const [ showLoader, setShowLoader ] = useState(false);

  /**
   * Handles input change
   * 
   * @param {event} e 
   */
  const onInputChange = (e) => {
    onEdit(e.target.value);
    if(!edited) {
      changeEditedFieldStatus(true);
    }
  }

  /**
   * Validates input
   * 
   * @param {string} fieldValue 
   * @param {string} fieldName 
   * 
   * @returns {boolean}
   */
  const validateInput = (fieldValue, fieldName) => {
    let regularExpression;

    switch (fieldName) {
      case 'email':
        regularExpression = /^(([^.\s@]+(\.[^.\s@]+)*))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
        break;
      case 'phone_number':
        regularExpression = /^[0-9+\-/\s]*$/;
        break;
      default:
        regularExpression = null;
    }

    if(regularExpression) {
      if(regularExpression.test(fieldValue)) {
        return true;
      }
      return false;
    }
    return true;
  }


  /**
   * Change component state based on middleware response
   * 
   * @param {object} response 
   */
  const handleStateOnMiddlewareUpdate = response => {
    if(response && response.ok) {
      hideEditableContent();
      if(showError) {
        setShowError(false);
      }
    } else {
      showErrorPopup('network')
    }
  }

  /**
   * Handles changed data save
   */
  const onChangeSave = () => {
    if(validateInput(content.value, fieldName)) {
      setShowLoader(true)
      hideErrorPopup()
      updateMiddleware(content.value)
        .then(response => {
          handleStateOnMiddlewareUpdate(response)
          setShowLoader(false)
        })
        .catch(e => {
          console.log(e)
          handleStateOnMiddlewareUpdate()
          setShowLoader(false)
        })
    } else {
      setShowError(true);
    }
  }

  /**
   * Close editing field and dispatch field initial value
   * 
   * @param {string} initialValue 
   */
  const onCancel = (initialValue) => {
    onEdit(initialValue);
    hideEditableContent();
    hideErrorPopup()
    if(showError) {
      setShowError(false);
    }
  }

  if(shouldEdit) {
    return (
      <div className="edit-text">
        <p className='edit-text__message'>{message}</p>
        <input 
          type="text" 
          value={content.value !== null ? content.value : ''} 
          onChange={onInputChange}
          className={`edit-text__input ${showError ? 'edit-text__input--error' : '' }`}
        />
        {showError && <p className="edit-text__error-message">Please enter valid format.</p>}
        <div className="edit-text__buttons">
          <SaveButton onClick={onChangeSave} enableSave={edited} showLoader={showLoader} />
          <CancelButton onClick={() => onCancel(initialValue)} />
        </div>
      </div>
    )
  }
  return <Text value={content.value} />
}

export default EditText;