import React, { useState } from 'react';

import { TOGGLE_MODAL } from '../store/actions';
import { useAppDispatch } from '../store';
//Component
import RadioOrCheckInput from './RadioOrCheckInput';
import ConfirmButton from './ConfirmButton';
import { useTranslation } from 'react-i18next';

//Style 
import './_modal-payment.scss';


/**
 * Component
 * render payment modal if it is opened
 *
 *  @param {object} param - destructured props object
 *  @param {object} pram.eventData - Object with sponsor modal data
 *  @return {ReactElement}
 */
function ModalPayment({ eventData }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  //Local state
  const [modalPaymentLocalState, setModalPaymentLocalState] = useState({
    mothlyFee: 0,
    isOtherSelected: false,
    isReadyToSubmit: false,
  });
  const paymentOptions = ["1000kr", "3000kr", "5000kr", "other"];
  /*
  * Close dialog on click
  */
  const closeModalOnClick = (e) => dispatch({ type: TOGGLE_MODAL, modalName: 'closeModal' });
  /*
  * If user click on radio button with other value, input field will be shown 
  */
  const showOtherAmountField = (e) => setModalPaymentLocalState({ ...modalPaymentLocalState, isOtherSelected: true });
  /*
  * Set mothly fee from user choice ( radio button )   
  */
  const setFeeForUser = fee => e => setModalPaymentLocalState({ ...modalPaymentLocalState, mothlyFee: fee, isReadyToSubmit: true });
  /*
  * If number input field is not empty set monthlyFee from value of field
  */
  const setOtherFeeForUser = e => e.target.value !== '' ? setModalPaymentLocalState({ ...modalPaymentLocalState, mothlyFee: e.target.value + 'kr', isReadyToSubmit: true }) : setModalPaymentLocalState({ ...modalPaymentLocalState, isReadyToSubmit: false });
  /*
  * I will leave it for later when we figure out what we will do on submit. Probabby we will call thank you modal 
  */
  function submitFrom() {
    console.log(modalPaymentLocalState.mothlyFee);
  }
  //Style 
  const headerImageBg = {
    backgroundImage: `url(${eventData.becomeSponsor.image})`,
  }
  return (
    <div className="modal-payment">
      <div className="modal-payment__head" style={headerImageBg}>
        <span onClick={closeModalOnClick} className="close-modal"></span>
      </div>
      <div className="modal-payment__wrapper">
        <div className="modal-payment__modal-title">
          {eventData.becomeSponsor.name}
        </div>
        <div className="modal-payment__modal-content">
          {eventData.becomeSponsor.content}
        </div>
        <div className="modal-payment__form">
          <div className="modal-payment__form-title">
            Choose your monthly fee
          </div>
          <div className="modal-payment__form-content">
            You can change fee or cancel your sponsorship at any time
          </div>
          <div className="modal-payment__fee">
            {paymentOptions.map((fee, index) =>
              <RadioOrCheckInput
                key={index}
                type="radio"
                name="monthly-fee"
                value={fee}
                checked={fee === modalPaymentLocalState.mothlyFee ? true : false}
                onInputChange={fee === "other" ? showOtherAmountField : setFeeForUser(fee)}
              />
            )}
            <input onChange={setOtherFeeForUser} className={modalPaymentLocalState.isOtherSelected ? 'modal-payment__other-amount' : 'modal-payment__other-amount hide-input'} type="number" placeholder="0" />
          </div>
          <ConfirmButton className={modalPaymentLocalState.isReadyToSubmit ? "modal-payment__submit" : "modal-payment__submit inactive-submit"} onClick={submitFrom}>{t('buttons.confirm')}</ConfirmButton>
        </div>
      </div>
    </div>
  );
}

export default ModalPayment;