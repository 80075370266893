import React from 'react'
import { useAppState, useAppApi } from '../store';
import { useTranslation } from "react-i18next";


import warning from './images/t-warning-copy@3x.png';
import './ErrorPopup.scss';

/**
 * ErrorPopup component
 * 
 * @returns {JSX Element}
 */
export default function ErrorPopup() {

  const { errorPopup } = useAppState();
  const { hideErrorPopup } = useAppApi();
  const { t } = useTranslation();
  
  return (
    <div className={`error-popup ${errorPopup.showError ? 'error-popup--show' : ''}`}>
      <img src={warning} alt="warning" className="error-popup__image"/>
      <div className="error-popup__content" >
        <h3 className="error-popup__content-title">{t(`errorMessages.${errorPopup.errorType}.error`)}</h3>
        <p className="error-popup__content-message">{t(`errorMessages.${errorPopup.errorType}.explanation`)}</p>
      </div>
      <button onClick={() => hideErrorPopup(errorPopup.errorType)} className="error-popup__close-btn"></button>
    </div>
  )
}
