import React from 'react';
import { useTranslation } from "react-i18next";
import './Text.scss';

/**
 * Text component
 * 
 * @param {object} props 
 */
function Text (props) {

  const { value, value_en, fieldName } = props;
  const { t, i18n: {language: lang} } = useTranslation();

  /**
   * Prepares text value
   * 
   * @param {string} fieldName 
   * @param {'string'} value 
   * @param {'string'} value_en 
   */
  const prepareTextValue = (fieldName, value, value_en) => {
    switch (fieldName) {
      case 'privacyPolicy':
      case 'partyStatus':
        return t(`translatableFieldConntent.${fieldName}`)
      case 'post_number':
      case 'address':
        return value ? value : <span>{t('translatableFieldConntent.waitingSync')}</span>
      case 'joined':
        return lang === 'is' ? value : value_en
      default:
        return value;
    }
  }
  
  return (
    <div className="text">{prepareTextValue(fieldName, value, value_en)}</div>
  );
}

export default Text;