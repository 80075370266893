import React from 'react';
import { useTranslation } from "react-i18next";
import ConfirmButton from './ConfirmButton';

import './SessionExpired.scss'

/**
 * ErrorPage component
 * 
 * @returns {JSX Element}
 */
function SessionExpired() {

  const { t } = useTranslation();

  return (
    <div className="session-expired">
      <div className="session-expired__content">
        <div className="session-expired__dialog-box">
          <div className="session-expired__dialog-box-image">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
              <g fill="none" fillRule="evenodd" strokeLinecap="square">
                  <g stroke="#777">
                      <g>
                          <g transform="translate(-698 -267) translate(480 223) translate(219 45)">
                              <path d="M28.316.469L12.004.469.469 12.004.469 28.316 12.004 39.851 28.316 39.851 39.851 28.316 39.851 12.004zM20.16 8.908L20.16 23.911"/>
                              <circle cx="20.16" cy="30.474" r="1"/>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
          </div>
          <h3 className="session-expired__dialog-box-title">{t('sessionExpiredPage.title')}</h3>
          <p className="session-expired__dialog-box-text">{t('sessionExpiredPage.text')}</p>
          <div className="session-expired__dialog-box-buttons">
            <ConfirmButton 
              className="confirm-button" 
              onClick={() => window.location.href = "https://xd.is/"}
              showLoader={false}
            >
              {t('sessionExpiredPage.buttonText')}
            </ConfirmButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionExpired;