import React from 'react'
//Translations
import { useTranslation } from "react-i18next";
//Store
//Style
import './TermsAndConditionsText.scss';

/**
 * Terms and Condition Translated Text
 * In the middle is action span which open Terms & Conditions dialog ( In this moment we dont have that modal, so we are using privacy policy,  But when we make it we will replace it )
 * @returns {JSX Element}
 * 
 */
export default function TermsAndConditionsText() {

  const { t } = useTranslation();

  return (
    <div className="terms-text">
      {t('termsAndConditionsFirstPart')}
      <a href="https://xd.is/wp-content/uploads/2022/01/Pers%C3%B3nuverndarstefna-Sj%C3%A1lfst%C3%A6%C3%B0isflokksins-140122.pdf" target="_blank" rel="noreferrer" > {t('termsAndConditions')}</a>
      {t('termsAndConditionsSecondPart')}
    </div>
  )
}
