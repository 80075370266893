import React from 'react';
import { useTranslation } from "react-i18next";
import './DialogBox.scss';

/**
 * DialogBox component
 * 
 * @param {object} param - Destructured props object.
 * @param {string} param.image - DialogBox Icon
 * @param {function} param.close - Used for closing modal that renders DialogBox
 * @param {string} param.title - DialogBox title
 * @param {string} param.text - DialogBox text
 * @param {function} param.renderButtons - Function that render DialogBox buttons
 * @param {boolean} param.textAsNote
 * 
 * @return {JSX Element}
 * 
 */
const DialogBox = ({ image, close, title, text, renderButtons, textAsNote}) => {
  const { t } = useTranslation();

  return (
    <div className="dialog-box">
      <button className="dialog-box__close" onClick={close}></button>
      <div className="dialog-box__image">{image}</div>
      <h3 className="dialog-box__title">{title}</h3>
      <p className="dialog-box__text">{textAsNote && <span>{`${t('note')}: `}</span>}{text}</p>
      <div className="dialog-box__buttons">{renderButtons()}</div>
    </div>
  );

}


export default DialogBox;