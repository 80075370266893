import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoader from './ButtonLoader';

import './SaveButton.scss'; 

/**
 * SaveButton component
 * 
 * @param {object} param - destructured props object
 * @param {function} param.onClick - handler for button onClick event
 * @param {boolean} param.enableSave - used to determin whether or not button should be enabled
 */
const SaveButton = ({ onClick, enableSave, showLoader=false, color='blue' }) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={ enableSave ? false : true }
      onClick={onClick}
      className={`save-button ${enableSave ? 'save-button--enabled' : ''}`}
    >
      {t('buttons.save')}
      {showLoader && <ButtonLoader color={color}/>}
    </button>
  )
}

export default SaveButton;