import React from 'react'
import { useTranslation } from "react-i18next";

import './RadioOrCheckInput.scss';

/**
 * This is componennt which create checkboxes or radio buttons 
 * @param {String} value - Value of input field
 * @param {String} type - This will determine if this will render checkbox or radio button
 * @param {String} className - If need special class name 
 * @param {String} label  
 * @param {Boolean} checked 
 * @param {function} onInputChange - handler for button onClick event 
 * @param {Boolean} isRequired - We will need this maybe in future 
 */
export default function RadioOrCheckInput({ value, name, type, className = '', label = '', checked, onInputChange, isRequired }) {

  const { t } = useTranslation();

  return (
    <div className={className + ' radio-or-check-item'} >
      <input onChange={onInputChange} type={type} name={name} value={value} checked={checked} />
      <span className={'checkmark checkmark-' + type}></span>
      {value && <span className={'input-text'}>{label !== '' ? t(`${label}`) : t(`${value}`)}</span>}
    </div>
  )
}
