import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const enTranslations = require('./translations/en/translations.json');
const isTranslations = require('./translations/is/translations.json');

const resources = {
  "en":  {
    translation: enTranslations
  },
  "is": {
    translation: isTranslations
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "is",
    // use en if detected lng is not available - So I will keep this just in case
    fallbackLng: "en",
    keySeparator: '.', 
    // This are allowed languages in our app, again this app can work without it, but I will leave this just in case 
    // whitelist: ['en', 'is'],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;