import React from 'react';
import ButtonLoader from './ButtonLoader';

import './ConfirmButton.scss'; 

/**
 * ConfirmButton component
 * 
 * @param {object} props - component props
 */
const ConfirmButton = ({onClick, className, children, showLoader=false, color='blue' }) => {
  return (
    <button
      onClick={onClick}
      className={`confirm-button ${className}`}
    >
      { children}
      { showLoader && <ButtonLoader color={color} />}
    </button>
  )
}

export default ConfirmButton;