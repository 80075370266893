import React from 'react';
import { useAppDispatch, useAppState } from '../store';
import Barcode from 'react-barcode';
import Image from './Image';
import './ModalBarcode.scss';

/**
 * ModalLeaveParty component
 * 
 * @returns {JSX Element}
 */
function ModalLeaveParty() {

  const modalDispatch = useAppDispatch();

  const {
    user: {
      profile: {
        profile_image: { content: { value: profileImage } },
        full_name: { content: { value: name } },
        barcode: { content: { value: barcode } }
      },
    }
  } = useAppState();

  /**
   * Handles modal close
   */
  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null });

  return (
    <div className="modal-barcode">
      <div className="modal-barcode__inner-wrapper">
        <Image value={profileImage} />
        <span className="modal-barcode__name">{name}</span>
        <div>
          <Barcode
            format="CODE128"
            value={barcode}
            width={5}
            height={150}
            flat={true}
            font="Roboto"
            fontSize={30}
          /></div>
        <button className="modal-barcode__close-button" onClick={closeModal}></button>
      </div>
    </div>
  );
}

export default ModalLeaveParty;