import React from 'react';
import '../global-styles/_reset.scss';
import '../global-styles/_font-families.scss';
import ActivityTracker from './ActivityTracker'
import Loader from './Loader';
import { StoreProvider } from '../store';
import ErrorPopup from '../components/ErrorPopup'
import Routes from '../routes';

function App() {
  return (
    <>
    <StoreProvider>
      <ActivityTracker />
      <Routes />
      <Loader />
      <ErrorPopup />
    </StoreProvider>
  </>
  );
}

export default App;
