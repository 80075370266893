import React, { useReducer, useEffect } from 'react';
import produce from 'immer';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppApi } from '../store';


//Components
import InputField from './InputField';
import ConfirmButton from './ConfirmButton';
import RadioOrCheckInput from './RadioOrCheckInput';
import ChoiceInputWithTitle from './ChoiceInputWithTitle';
import TermsAndConditionsText from './TermsAndConditionsText';
import ErrorMessage from './ErrorMessage';

import warning from './images/c-warning.svg';


const validateEmail = email => {
  const regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regEx.test(email)
}

const validatePhoneNumber = phoneNumber => {
  const regEx = /^[0-9+\-/\s]*$/;
  return regEx.test(phoneNumber)
}

const reducer = (state, action) =>

  //Reducer
  produce(state, draft => {
    switch (action.type) {
      case 'TOGGLE_RADIO':
        draft.inputFields[action.name].selectedValue = action.value;
        draft.inputFields[action.name].isReady = true;
        break;
      case 'ADD_CHECKBOX_VALUE':
        draft.inputFields[action.name].selectedValue.push(action.value);
        draft.inputFields[action.name].selectedValue.length !== 0
          ? draft.inputFields[action.name].isReady = true
          : draft.inputFields[action.name].isReady = false
        break;
      case 'REMOVE_CHECKBOX_VALUE':
        let newArray = draft.inputFields[action.name].selectedValue.filter(model => model !== action.value)
        draft.inputFields[action.name].selectedValue = newArray;
        if (draft.inputFields[action.name].selectedValue.length === 0) {
          draft.inputFields[action.name].isReady = false
        }
        break;
      case 'UPDATE_INPUT_FIELD':
        draft.inputFields[action.data.name].value = action.data.value;
        draft.inputFields[action.data.name].value !== '' || draft.inputFields[action.data.name].isRequired === false
          ? draft.inputFields[action.data.name].isReady = true
          : draft.inputFields[action.data.name].isReady = false
        if (draft.inputFields[action.data.name].invalidValue) {
          if (action.data.name === 'email') {
            if (validateEmail(action.data.value)) draft.inputFields[action.data.name].invalidValue = false;
          }
          if (action.data.name === 'phone_number') {
            if (validatePhoneNumber(action.data.value)) draft.inputFields[action.data.name].invalidValue = false;
          }
        }
        break;
      case 'CHECK_FORM':
        action.data.invalidFields.forEach(field => draft.inputFields[field].invalidValue = true)
        draft.isFormReady = false;
        draft.isFormSubmited = true;
        if (action.data.invalidFields.length === 0 && action.data.notReadyFields.length === 0) {
          draft.isFormReady = true;
        }
        break;
      case 'REMOVE_WARNING':
        draft.isFormReady = true;
        break;
      case 'TOGGLE_ORGANIZATIONS_LANGUAGE':
        draft.inputFields.sub_organizations_general.value = action.value
        break;
      case 'TOGGLE_BUTTON_LOADER':
        draft.showButtonLoader = action.value
        break;
      default:
        break;
    }
  })

// State
const formLocalState = {
  inputFields: {
    full_name: {
      value: '',
      isReady: true,
      ref: null,
      type: 'name',
      isReadOnly: true,
      isRequired: true,
      text: 'Full name',
      name: 'full_name',
      className: '',
      fieldType: 'input'

    },
    email: {
      value: '',
      isReady: null,
      ref: null,
      type: 'email',
      isReadOnly: false,
      isRequired: true,
      invalidValue: false,
      text: 'Email',
      name: 'email',
      className: '',
      fieldType: 'input'
    },
    phone_number: {
      value: '',
      isReady: null,
      ref: null,
      type: 'number',
      isReadOnly: false,
      isRequired: true,
      invalidValue: false,
      text: 'Phone Number',
      name: 'phone_number',
      className: '',
      fieldType: 'input'
    },
    language: {
      value: ['en', 'is'],
      type: 'radio',
      isReady: null,
      isRequired: true,
      name: 'language',
      title: 'language',
      selectedValue: ''
    },
    sub_organizations_general: {
      value: [],
      isReady: null,
      isRequired: true,
      type: 'checkbox',
      name: 'sub_organizations_general',
      title: 'memberOrganizationBelong.title',
      subtitle: 'memberOrganizationBelong.subtitle',
      selectedValue: []
    },
    accepted_terms: {
      value: 'terms',
      name: 'accepted_terms',
      type: 'checkbox',
      isReady: null,
      isRequired: true,
      selectedValue: []
    }
  },
  isFormSubmited: false,
  isFormReady: false,
  showButtonLoader: false
}

/**
 * SignUpForm component
 * 
 * @returns {JSX Element}
 */
function SignUpForm(props) {

  const { t, i18n } = useTranslation();

  const testGenderFemale = true;

  const getOrganizationNames = (organizations, language) => {
    if (testGenderFemale) {
      return organizations.map(organization => language === 'is' ? organization.name : organization.name_en)
    }
    return organizations
      .filter(organization => organization.id !== 13)
      .map(organization => language === 'is' ? organization.name : organization.name_en)
  }

  const globalDispatch = useAppDispatch()
  const { updateRegisterRoute, fetchUserData, showErrorPopup } = useAppApi();
  const [state, dispatch] = useReducer(reducer, formLocalState);
  const inputFields = state.inputFields;


  // ====== On change  ==========
  const getValueOfRadioInput = name => e => e.target.checked ? dispatch({ type: 'TOGGLE_RADIO', value: e.target.value, name: name }) : null;

  const getValueOfCheckInput = name => e => e.target.checked
    ? dispatch({ type: 'ADD_CHECKBOX_VALUE', value: e.target.value, name: name, checked: e.target.checked })
    : dispatch({ type: 'REMOVE_CHECKBOX_VALUE', value: e.target.value, name: name, checked: e.target.checked });

  const inputFieldHandler = (name, value, index) => (e) => dispatch({ type: 'UPDATE_INPUT_FIELD', data: { value: e.target.value, index, name } });

  // ===== On submit form =====  
  const validateForm = (invalidFields = [], notReadyFields = []) => dispatch(
    { type: 'CHECK_FORM', data: { invalidFields, notReadyFields } }
  );


  /**
   * Prepares organization data in relation to selected values array
   * 
   * @param {array} organizations 
   * @param {array} selectedValues 
   */
  const prepareOrganizations = (organizations, selectedValues) => {
    const mappedOrganization = organizations.map(org => {
      const isSelected = selectedValues.indexOf(org.name) > -1 || selectedValues.indexOf(org.name_en) > -1;
      if (isSelected) {
        org = { ...org, checked: true };
        return org;
      }
      return org;
    })
    return mappedOrganization;
  }

  /**
   * Prepares form data for submit
   * 
   * @param {object} stateInputs 
   * @param {array} organizations 
   */
  const prepareFormData = (stateInputs, organizations) => {
    const preparedData = {};

    Object.entries(stateInputs).forEach(([key, value]) => {
      switch (key) {
        case 'full_name':
        case 'email':
        case 'phone_number':
          preparedData[key] = value.value;
          break;
        case 'language':
          preparedData[key] = value.selectedValue;
          break;
        case 'contact_email':
        case 'contact_phone':
          preparedData[key] = value.selectedValue === 'yes' ? true : false
          break;
        case 'sub_organizations_general':
          preparedData[key] = prepareOrganizations(organizations, value.selectedValue)
          break;
        case 'accepted_terms':
          preparedData[key] = value.selectedValue.length > 0 ? true : false
          break;
        default:
          break;
      }
    })

    return preparedData;
  }



  /**
   * Checks if form data is redy for submit
   * 
   * @param {object} inputFields
   * 
   * @returns {boolean} 
   */
  const checkForm = (inputFields) => {
    let readyForSubmit;
    const noValueArray = []
    const notValidArray = []

    // Go through all fields ( Radio, checkbox & input ) and check are they all set and if so check if email and phone fields have valid values
    Object.values(inputFields).forEach(item => {
      if (item.isRequired && !item.isReady) {
        noValueArray.push(item.name);
      } else if (item.isReady) {
        if (item.name === 'email' && !validateEmail(item.value)) {
          notValidArray.push(item.name)
        }
        if (item.name === 'phone_number' && !validatePhoneNumber(item.value)) {
          notValidArray.push(item.name)
        }
      }
    });

    if (noValueArray.length > 0 || notValidArray.length > 0) {
      validateForm(notValidArray, noValueArray)
      readyForSubmit = false;
    } else {
      readyForSubmit = true;
    }

    return readyForSubmit;
  }

  //Submit form, check is it valid and 
  const submitForm = (e) => {
    const isReady = checkForm(state.inputFields);

    if (isReady) {
      // if ready update middleware 'register' route with prepared data
      dispatch({ type: 'TOGGLE_BUTTON_LOADER', value: true })
      updateRegisterRoute(prepareFormData(state.inputFields, props.userData.sub_organizations_general))
        .then(response => {
          if (!response.ok) {
            // in case registration was successful but we faild to fetch the data
            // error 403 will be returned, in that case we proceed with fetching data
            if (response.status !== 403) {
              showErrorPopup('network')
              dispatch({ type: 'TOGGLE_BUTTON_LOADER', value: false })
            } else {
              return fetchUserData('/me')
            }
          } else {
            // fetch user data
            return fetchUserData('/me')
          }
        })
        .then(response => {
          if (!response.ok) {
            showErrorPopup('network')
            dispatch({ type: 'TOGGLE_BUTTON_LOADER', value: false })
            throw new Error(`${response.statusText}`)
          }
          return response.json()
        })
        .then(data => {
          // add user data to local state
          globalDispatch({ type: 'ADD_USER_DATA', payload: data.member.data })
          // disable loader
          globalDispatch({ type: "SHOW_LOADER", payload: { show: false } });
          // redirect to 'profile' route and sand data to triger welcome modal
          props.redirectToProfile({ showWelcomeModal: true })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (!state.full_name) {
      dispatch({ type: 'UPDATE_INPUT_FIELD', data: { value: props.userData.name, name: 'full_name' } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'TOGGLE_ORGANIZATIONS_LANGUAGE',
      value: getOrganizationNames(props.userData.sub_organizations_general, i18n.language)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  useEffect(() => {
    if (state.isFormSubmited) {
      const isReady = checkForm(state.inputFields);

      if (isReady) dispatch({ type: 'REMOVE_WARNING' });
    }
  })

  return (
    <div className="sign-up">
      {Object.keys(state.inputFields).map((key, index) => {
        if (inputFields[key].type === 'number' ||
          inputFields[key].type === 'email' ||
          inputFields[key].type === 'name') {
          return <div className={!state.inputFields[key].isReady && state.isFormSubmited && inputFields[key].isRequired
            ? 'sign-up__field-wrapper sign-up__field-wrapper--error'
            : 'sign-up__field-wrapper'}
            key={index}>
            <InputField key={index}
              value={inputFields[key].value === null ? '' : inputFields[key].value}

              isReady={state.inputFields[key].isReady}
              className={state.inputFields[key].isReady === false || state.inputFields[key].invalidValue
                ? 'modal-form__input-wrapper-error' : null}
              text={t(`registerForm.${inputFields[key].name}`)}
              isReadOnly={inputFields[key].isReadOnly}
              name={inputFields[key].name}
              type={inputFields[key].type}
              isRequired={inputFields[key].isRequired}
              onChange={inputFieldHandler(inputFields[key].name,
                inputFields[key].value, index)}
              isFormSubmited={state.isFormSubmited}
            />
            {
              (state.isFormSubmited && !state.inputFields[key].isReady && inputFields[key].isRequired) ||
                inputFields[key].invalidValue ?
                <ErrorMessage canBeInvalidValue={true} hasInputValidation={inputFields[key].invalidValue} /> : null
            }

          </div>
        } else {
          const errorClass = (type, name) => {
            let error = ''
            if (type === 'radio') {
              state.inputFields[name].selectedValue === '' && state.isFormSubmited ? error = 'sign-up__radio-wrapp-error' : error = ''
            } else {
              state.inputFields[name].selectedValue.length === 0 && state.isFormSubmited ? error = 'sign-up__radio-wrapp-error' : error = ''
            }
            return error
          }
          if (inputFields[key].title !== undefined) {
            return (
              <div className={!state.inputFields[key].isReady && state.isFormSubmited && inputFields[key].isRequired
                ? 'sign-up__field-wrapper sign-up__field-wrapper--error'
                : 'sign-up__field-wrapper'}
                key={index}>
                <ChoiceInputWithTitle
                  title={inputFields[key].title}
                  subtitle={inputFields[key].subtitle}
                  options={inputFields[key].value}
                  type={inputFields[key].type}
                  isRequired={inputFields[key].isRequired}
                  errorClassName={errorClass(inputFields[key].type, inputFields[key].name)}
                  onInputChange={inputFields[key].type === 'radio' ? () => getValueOfRadioInput(inputFields[key].name) : () => getValueOfCheckInput(inputFields[key].name)}
                  isFormSubmited={state.isFormSubmited}
                  key={index}
                />
                {state.isFormSubmited && !state.inputFields[key].isReady && inputFields[key].isRequired ? <ErrorMessage /> : null}
              </div>
            )
          } else {
            return (
              <div key={index} className={state.inputFields.accepted_terms.selectedValue.length === 0 && state.isFormSubmited === true
                ? 'sign-up__radio-wrapp-error terms-and-conditions-text-wrapper'
                : "terms-and-conditions-text-wrapper"} >
                <div className='terms-and-conditions-text-wrapper__child'>
                  <RadioOrCheckInput
                    type={inputFields[key].type}
                    key={index}
                    value={true}
                    name={inputFields[key].name}
                    onInputChange={getValueOfCheckInput(inputFields[key].name)}
                    className='terms-and-conditions-checkbox'
                  />
                  <TermsAndConditionsText />
                </div>
                {state.isFormSubmited && !state.inputFields[key].isReady && inputFields[key].isRequired ? <ErrorMessage /> : null}
              </div>
            )
          }
        }
      })
      }
      {state.isFormSubmited && !state.isFormReady && (
        <div className="sign-up__warning">
          <img src={warning} alt="warning" />
          <span>{t('registerForm.warning')}</span>
        </div>
      )}
      <ConfirmButton onClick={submitForm} showLoader={state.showButtonLoader} className='modal-form__submit'> {t('join')} </ConfirmButton>
    </div>
  )
}

export default SignUpForm; 
