import React from 'react'
import ActionButton from './ActionButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store';
import './LeavePartyTerms.scss'

export default function LeavePartyTerms() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const toggleModal = () => dispatch({ type: 'TOGGLE_MODAL', modalName: 'leave' })

  return (
    <div className='leave-party-terms'>
      <p className='leave-party-terms__text'>{t('leavePartyContent.text')}</p>
      <ActionButton
        onClick={toggleModal}
        buttonText={t('buttons.leaveParty')} />
    </div>
  )
}
