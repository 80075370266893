import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';


import AuthRoute from './AuthRoute';
import Home from '../components/Home';
import Register from '../components/Register';
import Profile from '../components/Profile';
import Terms from '../components/Terms';
import Party from '../components/Party';
import Settings from '../components/Settings';
import SessionExpired from '../components/SessionExpired';

import { createBrowserHistory } from "history";


const history = createBrowserHistory();


/**
 * Routes component
 *
 * @param {object} props
 * @return {ReactElement}
 */

function Routes(props) {

  return (
    <Router >
      {props.children}
      <Route path="/" exact component={Home} />
      <Route path="/register" exact component={Register} />
      <Route path="/terms" exact component={Terms} />
      <Route path="/session-expired" exact component={SessionExpired} />
      <AuthRoute path="/profile" component={Profile} history={history} />
      <AuthRoute path="/party" component={Party} history={history} />
      <AuthRoute path="/settings" component={Settings} history={history} />
    </Router>
  );
}

export default Routes;