import {
  TOGGLE_MODAL,
  CHANGE_PROFILE_INFO,
  CHANGE_PARTY_INFO,
  CHANGE_SETTINGS,
  CHANGE_CURRENTLY_EDITING_FIELD,
} from './actions';

/**
 * Api
 *
 * @param {object} state
 * @param {function} dispatch
 * @return {object}
 */
function MyPagesApi(state, dispatch) {

  const changeUserProps = async (userProps) => {

    try {
      const response = await fetch(
        'middleware/user',
        { method: 'POST', body: JSON.stringify(userProps) }
      );
      const changedProps = await response.json();
      // dispatch(changeProps(changedProps));
      return changedProps;
    } catch (e) {
      throw new Error(e);
    }

  };

  const toggleModal = (modalName) => dispatch({ type: TOGGLE_MODAL, modalName });


  /**
   * Prepares action type
   * 
   * @param {string} screen - current app screen
   * 
   * @returns {function}
   */
  const prepareChangeInfoAction = screen => {
    let actionType;

    switch (screen) {
      case 'profile':
        actionType = CHANGE_PROFILE_INFO;
        break;
      case 'party':
        actionType = CHANGE_PARTY_INFO;
        break;
      case 'settings':
        actionType = CHANGE_SETTINGS;
        break;
      default:
        actionType = null;
    }

    return fieldName => value => {
      dispatch({ type: actionType, payload: { fieldName, value } });
    }
  }

  /**
   * Sets edited field editedFieldName property
   * 
   * @param {string} editedFieldName 
   */
  function changeEditedFieldName(editedFieldName) {
    dispatch({ type: CHANGE_CURRENTLY_EDITING_FIELD, payload: { fieldToUpdate: 'editedFieldName', editedFieldName } });
  }

  /**
   * Sets edited field edited property
   * 
   * @param {boolean} edited 
   */
  function changeEditedFieldStatus(edited) {
    dispatch({ type: CHANGE_CURRENTLY_EDITING_FIELD, payload: { fieldToUpdate: 'edited', edited } });
  }

  /**
   * Sets edited field initialValue property
   * 
   * @param {any} initialValue 
   */
  function changeEditedFieldInitialValue(initialValue) {
    dispatch({ type: CHANGE_CURRENTLY_EDITING_FIELD, payload: { fieldToUpdate: 'initialValue', initialValue } });
  }

  /**
   * Sets edited field screen property
   * 
   * @param {string} screen 
   */
  function changeEditedFieldScreen(screen) {
    dispatch({ type: CHANGE_CURRENTLY_EDITING_FIELD, payload: { fieldToUpdate: 'screen', screen } });
  }

  /**
   * Prompts confirm box on multiple field edits atempt
   * 
   * @param {string} editedFieldName 
   * @param {boolean} edited 
   * @param {any} initialValue 
   * @param {string} editedFieldScreen 
   */
  const promptConfirmBoxOnFieldChange = (editedFieldName, edited, initialValue, editedFieldScreen) => {
    if (edited) {
      if (window.confirm('Changes were made without saving. Would you like to save changes?')) {
        changeEditedFieldName('');
        changeEditedFieldStatus(false);
      } else {
        prepareChangeInfoAction(editedFieldScreen)(editedFieldName)(initialValue)
        changeEditedFieldName('');
        changeEditedFieldStatus(false);
      }
    }
  }

  /**
   * Prompts confirm box on route chage if eidited field is still active
   * 
   * @param {evnet} e
   * @param {stirn} editedFieldName 
   * @param {boolean} edited 
   * @param {any} initialValue 
   * @param {string} editedFieldScreen 
   */
  const promptConfirmBoxOnRoutChange = (e, editedFieldName, edited, initialValue, editedFieldScreen) => {
    if (edited) {
      if (window.confirm('It looks like you have been editing something. If you leave before saving, your changes will be lost.')) {
        prepareChangeInfoAction(editedFieldScreen)(editedFieldName)(initialValue)
        changeEditedFieldName('');
        changeEditedFieldStatus(false);
      } else {
        e.preventDefault();
      }
    }
  }


  /**
   * Fetch user data from /me route
   * @param {string} path
   * @returns {Promise}
   */
  const fetchUserData = path => fetch(path, {
    method: 'GET',
    cache: 'no-cache'
  })

  /**
   * Send data to endpoint
   * 
   * @param {string} path - example: 'update', 'register'...
   *
   */
  const sendUserData = path => data => {
    return fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }

  /**
   * Logout user by making POST request to /logout rout
   * 
   */
  const logoutUser = () => fetch("/logout", { method: 'POST', credentials: 'include' });

  /**
   * Delete user by making POST request to /delete rout
   * 
   * @param {object} data
   * 
   */
  const deleteUser = data => fetch("/leave", {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  /**
   * Wrapper functon that adds timeout handling for fetch api
   * 
   * @param {function} fetchFunc 
   * @param {any} data 
   * @param {number} timeout 
   */
  const fetchWithTimeout = (fetchFunc, data, timeout) => {
    return Promise.race([
      fetchFunc(data),
      new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);
  }

  /**
   * Sends changed data from editable fields to 'update' route
   * 
   * @param {string} screen 
   * @param {string} fieldName 
   * 
   * @returns {Promise}
   */
  const updateMiddlewareOnSaveChange = (screen, fieldName) => fieldValue => {
    const updatedData = {
      [screen]: {
        [fieldName]: {
          content: {
            value: fieldValue
          }
        }
      }
    }
    return fetchWithTimeout(sendUserData('/update'), updatedData, 30000);
  }

  /**
   * Sends data to 'update' route
   * 
   * @param {any} data 
   * @returns {Promise}
   */
  const updateMiddleware = data => fetchWithTimeout(sendUserData('/update'), data, 30000);

  /**
   * Sends data to 'register' route
   * 
   * @param {*} data 
   * @returns {Promise}
   */
  const updateRegisterRoute = data => fetchWithTimeout(sendUserData('/register'), data, 30000)

  /**
   * Returns selected language 
   * 
   * @param {object} user 
   * @returns {string}
   */
  const getSelectedLanguage = user => user.settings.language.content.value.selectedOption;

  /**
   * Uploads image to '/update' route
   * 
   * @param {object} data 
   * @returns {Promise}
   */
  const uploadImage = data => fetch('/update', { method: 'POST', body: data })


  /**
   * Hides error popup
   */
  const hideErrorPopup = type => {
    dispatch({ type: "SHOW_ERROR", payload: { showError: false, errorType: type ? type : '' } });
  }

  /**
   * Shows error popup
   * 
   * @param {string} type 
   */
  const showErrorPopup = type => {
    if (!state.errorPopup.showError) {
      dispatch({ type: "SHOW_ERROR", payload: { showError: true, errorType: type } });
    } else {
      setTimeout(() => {
        dispatch({ type: "SHOW_ERROR", payload: { showError: true, errorType: type } });
      }, 500);
    }
  }


  const api = {
    changeUserProps,
    toggleModal,
    prepareChangeInfoAction,
    changeEditedFieldName,
    changeEditedFieldStatus,
    changeEditedFieldInitialValue,
    changeEditedFieldScreen,
    promptConfirmBoxOnFieldChange,
    promptConfirmBoxOnRoutChange,
    fetchUserData,
    getSelectedLanguage,
    updateMiddlewareOnSaveChange,
    updateMiddleware,
    updateRegisterRoute,
    uploadImage,
    showErrorPopup,
    hideErrorPopup,
    logoutUser,
    deleteUser
  };


  return [state, dispatch, api];
}

export default MyPagesApi;