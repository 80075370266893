import React, { useState, useEffect } from 'react'
import ConfirmButton from './ConfirmButton';
import CancelButton from './CancelButton';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppApi} from '../store';
import { useHistory } from 'react-router-dom';



import './ModalInnerDialog.scss';

/**
 * ModalSessionAboutToExpire component
 * 
 * @returns {JSX Element}
 */
export default function ModalSessionAboutToExpire() {

  const [ count, setCount ] = useState(60)

  const { t } = useTranslation();
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { logoutUser } = useAppApi();


  /**
   * Logout user and redirect to xd.is
   */
  const confirmLogOut = () => {
    logoutUser()
    window.location.href = 'https://xd.is/';
  }

  /**
   * Close modal
   */
  const close = () => {
    dispatch( {type: 'TOGGLE_MODAL', modalName: null})
  }

  useEffect(() => {
    let timer 
    if(count !== 0) {
      timer = setInterval(() => {
        setCount(count => count - 1)
      }, 1000)
    } else {
      logoutUser()
      dispatch({ type: "CHANGE_LOGGED_STATUS", payload: { value: true }})
      history.push("/session-expired")
    }

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <div className="modal-inner-dialog">
        <div className="dialog-box">
          <button className="dialog-box__close" onClick={close}></button>
          <div className="dialog-box__image">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
              <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                  <circle cx="20" cy="20" r="20" stroke="#777"/>
                  <path stroke="#777" d="M20 9.5v16.876"/>
                  <path fill="#777" d="M19 29h2v2h-2z"/>
              </g>
            </svg>
          </div>
          <h3 className="dialog-box__title">{t('modals.sessionAboutToExpire.title')}</h3>
          <p className="dialog-box__text">{t('modals.sessionAboutToExpire.text')} {count} {t('modals.sessionAboutToExpire.seconds')}</p>
          <div className="dialog-box__buttons">
            <ConfirmButton 
              className="confirm-button" 
              onClick={close}
              showLoader={false}
            >
              {t('modals.sessionAboutToExpire.confirmButton')}
            </ConfirmButton>
            <CancelButton onClick={confirmLogOut} customText={t('modals.sessionAboutToExpire.logoutButton')} />
          </div>
        </div>
    </div>
  )
}
