import React from 'react';
import { useAppDispatch } from '../store';
import { useTranslation } from 'react-i18next';

import './ModalChangeCommunication.scss';

/**
 *  ModalChangeCommunication component 
 *  
 *  @returns {JSX Element}
 */
function ModalChangeCommunication() {

  const modalDispatch = useAppDispatch();
  const { t } = useTranslation();

  /**
   * Handles modal close
   */
  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null });


  return (
    <div className="modal-wrapper">
      <div className="communication-modal">
        {/* Modal header start */}
        <div className="communication-modal__header">
          <span className="communication-modal__header-image">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25">
              <g fill="#00ADEF">
                <path d="M24.8 0H11.2c-.442 0-.8.358-.8.8v12.865L15.2 9.6h9.6c.442 0 .8-.358.8-.8v-8c0-.442-.358-.8-.8-.8z" />
                <circle cx="20" cy="14.4" r="3.2" />
                <circle cx="5.6" cy="14.4" r="3.2" />
                <path d="M10.02 20.346C9.137 19.823 7.652 19.2 5.6 19.2c-2.08 0-3.557.62-4.43 1.143C.449 20.774 0 21.564 0 22.406V24.8h11.2v-2.386c0-.846-.452-1.638-1.18-2.068zM24.42 20.346c-.883-.523-2.368-1.146-4.42-1.146-2.08 0-3.557.62-4.43 1.143-.721.431-1.17 1.221-1.17 2.063V24.8h11.2v-2.386c0-.846-.452-1.638-1.18-2.068z" />
              </g>
            </svg>
          </span>
          <span className="communication-modal__header-title">{t('communicationModalTranslate.headerTitle')}</span>
          <button className="communication-modal__header-close" onClick={closeModal}></button>
        </div>
        {/* Modal header end */}

        <div className="communication-modal__body">
          <p className="communication-modal__body-text">{t('communicationModalTranslate.bodyText')}</p>
        </div>
      </div>


    </div>
  )
}

export default ModalChangeCommunication;