import React from 'react';

//translations
import { useTranslation } from "react-i18next";
import { useAppApi } from "../store";
//Styles 
import './_secondary-navigation.scss';

import { ReactComponent as LogoutIcon } from '../components/images/logout-icon.svg';

function SecondaryNavigation({isVisible}) {

  const { t } = useTranslation();
  const { logoutUser, showErrorPopup, hideErrorPopup } = useAppApi();


  /**
   * Handles user logout
   * @param {event} e 
   */
  const handleLogout = e => {
    e.preventDefault();
    hideErrorPopup()
    logoutUser().then( response => {
      if(!response.ok) {
        showErrorPopup('network')
      } else {
        window.location.href = 'https://xd.is/';
      }
    })
  }


  return (
    <div className={ isVisible ? 'secondary-navigation secondary-navigation--visible' : 'secondary-navigation' }>
      <div className="navi-items">
        <div className="logout-menu-item secondary-navigation__item" onClick={handleLogout}>
            <LogoutIcon /><span>{t('logout')}</span>
        </div>
      </div>
    </div>
  );
}

export default SecondaryNavigation;