import React from 'react';
import { useAppDispatch } from '../store';
import './ModalTermsAndConditions.scss';


/**
 * ModalTermsAndConditions component
 * 
 * @returns {JSX Element}
 */
function ModalTermsAndConditions() {

  const modalDispatch = useAppDispatch();

  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null});

  return (
    <div className="modal-wrapper">
      <div className="terms-conditions">
        <button className="terms-conditions__close" onClick={closeModal}></button>

        <h2>SKILMÁLAR VEGNA SKRÁNINGAR Í SJÁLFSTÆÐISFLOKKINN</h2>

        <p>Sjálfstæðisflokknum er umhugað um að upplýsa flokksmenn um það sem er að gerast á vettvangi flokksins og stendur því fyrir víðtækri upplýsingamiðlun til flokksfélaga sinna. Jafnan felur aðild í sér að flokkur og flokksmaður eigi í samskiptum, m.a. um stefnumál, fundi, framboð, kosningar, fjármál auk annars sem tengist starfsemi flokksins.</p>

        <p>Með samþykkt skilmála þessa við umsókn um aðild að Sjálfstæðisflokknum undirgengst umsækjandi skilmála þá sem settir eru fram í þessu skjali um öflun, varðveislu og vinnslu persónuupplýsinga í þeim tilgangi sem að neðan er lýst. Bent skal þó á, að þegar sótt er um aðild, verða menn að samþykkja sérstaklega að flokkurinn hafi heimild til að eiga samskipti við félagsmanninn með tölvupósti eða í gegnum síma.</p>

        <p>Þær persónuupplýsingar sem gefnar eru upp við skráningu í flokksins eru vistaðar á skrifstofu hans. Til að tryggja áreiðanleika eru þær uppfærðar eftir þörfum, t.d. með samkeyrslu við þjóðskrá.</p>

        <p>Hér að neðan er yfirlit yfir upplýsingar sem flokkurinn kann að afla um flokksmenn og í hvaða tilgangi þessar upplýsingar eru varðveittar.</p>

        <h3>NAFN</h3>

        <p>Upplýsingarnar eru notaðar til að halda utan um nöfn þeirra sem aðild eiga að flokknum og einstökum félögum hans. Einnig til að halda utan um þá sem eru í styrktarmannakerfi, greiða félagsgjöld sem og þá sem gegna trúnaðarstörfum fyrir flokkinn. Nöfn, heimilisföng og kennitölur eru notuð til að halda utan um þá sem búa í hverju sveitarfélagi og kjördæmi og eiga m.a. rétt til setu á félagsfundum, njóta kjörgengi í viðkomandi félögum og ráðum og rétt til þátttöku í prófkjörum í aðdraganda sveitarstjórnarkosninga og alþingiskosninga.</p>

        <h3>KENNITALA</h3>

        <p>Fyrir utan ofangreint eru kennitölur einkum nýttar til að aðgreina þá sem bera sömu nöfn hvern frá öðrum. Kennitölur eru jafnframt notaðar til að afla upplýsinga um aldur og kyn flokksfélaga. Auk þess eru þær notaðar til að sækja mánaðarlega uppfærslu í þjóðskrá um lögheimili og aðrar upplýsingar sem þjóðskrá geymir. Sjá nánar í Persónuverndarstefnu flokksins.</p>

        <h3>HEIMILISFANG OG PÓSTNÚMER</h3>
        
        <p>Upplýsingar um heimilisfang og póstnúmer eru nýttar til þess að skrá flokksfélaga í rétt flokksfélög innan flokksins, þ.e. þau flokksfélög og ráð sem starfrækt eru í því sveitarfélagi/póstnúmeri þar sem viðkomandi býr. Þá eru upplýsingar um heimilisfang og póstnúmer jafnframt notaðar til að koma áleiðis upplýsingum í pósti varðandi flokksstarfið. Sjá nánar í Persónuverndarstefnu flokksins.</p>

        <h3>NETFANG</h3>
        
        <p>Netföng eru notuð í þeim tilgangi að senda upplýsingar til flokksfélaga, sem geta verið af hvaða tagi sem er og varðað t.d. viðburði, fundi, skilaboð, fjáröflum, stefnumál auk ýmissa hluta í tengslum við kosningar o.fl. Haldið er utan um netföng þeirra sem veitt hafa fyrir því heimild.</p>

        <h3>SÍMANÚMER</h3>
        
        <p>Símanúmer eru notuð í þeim tilgangi að miðla upplýsingum til flokksfélaga, sem geta verið af hvaða tagi sem er og varðað t.d. viðburði, fundi, skilaboð, fjáröflun, stefnumál auk ýmissa hluta í tengslum við kosningar o.fl. Haldið er utan um símanúmer þeirra sem veitt hafa fyrir því heimild.</p>

        <h3>MEÐHÖNDLUN FLOKKSSKRÁR</h3>
        
        <p>Aðgang að flokksskránni í heild hafa einungis þeir starfsmenn Sjálfstæðisflokksins sem vegna starfa sinna þurfa þess og er henni ekki deilt með þriðja aðila, sjá nánar í kafla um miðlun persónuupplýsinga í persónuverndarstefnu flokksins.</p>

        <p>Í miðlægri flokksskrá er haldið utan um ubpplýsingar um félagatal hvers félags og
        ráðs. Aðgang að slíku félagatali hafa einungis formenn þeirra og stjórnir í lögmætum
        tilgangi til þess að geta rækt skyldur við félagsmenn, m.a. vegna aðal- og félagsfunda
        auk prófkjara. Á það eftir atvikum við um flokksmenn í sérstökum trúnaðarstörfum
        eins og fundarstjóra og starfsmenn félagafunda auk kjör- og uppstillinganefnda í
        tengslum við val á listum flokksins. Stjórnir félaga geta veitt ákveðnum hópi
        félagsmanna takmarkaðan aðgang að félagatali, í húsnæði á vegum flokksins, til að
        miðla upplýsingum til annarra félagsmanna, t.a.m. í aðraganda alþingis- og
        sveitarstjórnarkosninga. Þegar slík skrá er útbúin koma fram kennitölur, nöfn,
        heimilisföng, póstnúmer og eftir atvikum símanúmer allra sem aðild eiga að
        félagi/ráði. Slíkur aðgangur að félagatali er einungis veittur gegn undirritun strangrar trúnaðarskuldbindingar um meðferð þess og að meðferð þess samræmist í einu og
        öllu reglum flokksins og lögum landsins.
        </p>

        <p>Í tengslum við prófkjör, röðun og kosningar til trúnaðarstarfa til ráða og félaga flokksins geta frambjóðendur í þeim fengið aðgang að kjörskrá, þ.e. viðkomandi félagatali, gegn því að undirrita strangar trúnaðarskuldbindingar um meðferð kjörskrárinnar, að meðferð hennar samræmist í einu og öllu reglum flokksins og lögum landsins. Við gerð kjörskrár eru netföng viðkomandi aldrei afhent.</p>

        <p>Megintilgangurinn með öflun og vinnslu persónupplýsinga er að flokkurinn geti efnt skyldur sínar og rækt hlutverk sitt gagnvart flokksmönnum. Upplýsingar eru ekki nýttar í öðrum tilgangi en þeirra var aflað til.</p>

        <p>Almenn lýsing á öflun og vinnslu persónupplýsinga er sett fram í persónuverndarstefnu Sjálfstæðisflokksins, sem hægt er að nálgast á heimasíðu flokksins, <a href="https://xd.is/" target="_blank" rel="noreferrer">www.xd.is</a>, og skoðast hún sem hluti skilmála þessara.</p>

        <p>Sjálfstæðisflokkurinn hvetur alla sem óska eftir aðild að flokknum til að kynna sér stefnuna vel.</p>
      </div>
    </div>
  );
}

export default ModalTermsAndConditions;