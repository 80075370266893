import React from 'react'
import whiteImage from './images/button-loading-white.png';
import blueImage from './images/loading-img.png';
import './ButtonLoader.scss'

/**
 * ButtonLoader component
 * 
 * @param {string} param.color - possible values: blue / white
 * @returns {JSX Element}
 */
export default function ButtonLoader({ color='blue'}) {
  return (
    <div className={`button-loader button-loader--${color}`}>
      <img 
        className="button-loader__image" 
        src={color === 'blue' ? whiteImage : blueImage} 
        alt="loading" 
      />
    </div>
  )
}
