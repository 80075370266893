import React, { useEffect } from 'react';
import Wrapper from './Wrapper';
import { useAppState, useAppApi } from '../store';
import SectionLabels from './SectionLabels';
import { useTranslation } from "react-i18next";


/**
 * Party component
 * 
 * @param {object} props
 * @returns {JSX Element} 
 */
function Party(props) {

  const { user } = useAppState();
  const labels = SectionLabels('partyContent');

  const { getSelectedLanguage } = useAppApi();
  const lang = getSelectedLanguage(user)
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  return <Wrapper labels={labels} data={user.party} screen="party" />;
}

export default Party;