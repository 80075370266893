import React from 'react';
import { useTranslation } from 'react-i18next';
import './CancelButton.scss';

/**
 * CancelButton component
 * 
 * @param {object} param - destructured props object
 * @param {function} pram.onClick - handler for button onClick event
 * 
 * @returns {JSX Element}
 */
const CancelButton = ({ onClick, customText }) => {
  const { t } = useTranslation();
  return (
    <button
      className="cancel-button"
      onClick={onClick}
    >
      { customText ? customText : t('buttons.cancel')}
    </button>
  )
}

export default CancelButton;