
import createStore from './createStore';
import myPagesReducer from './reducer';
import MyPagesApi from './MyPagesApi';

import paymentImage from '../components/images/payment-modal.png';
import generalImage from '../components/images/general-modal.png';

import paymentBannerImg from '../components/images/payment-banner.jpg';



const initialState = {
  currentEditingField: {
    editedFieldName: '',
    edited: false,
    initialValue: null,
    screen: ''
  },
  user: {
    profile: {
      profile_image: {
        type: 'edit',
        content: {
          value: null,
          type: 'image'
        }
      },
      ssn: {
        type: 'plain',
        content: {
          value: null,
          type: 'single'
        }
      },
      full_name: {
        type: 'plain',
        content: {
          value: '',
          type: 'single'
        }
      },
      email: {
        type: 'edit',
        content: {
          value: '',
          type: 'single'
        }
      },
      phone_number: {
        type: 'edit',
        content: {
          value: null,
          type: 'single'
        }
      },
      address: {
        type: 'plain',
        content: {
          value: '',
          type: 'single'
        }
      },
      post_number: {
        type: 'plain',
        content: {
          value: null,
          type: 'single'
        }
      },
      city: {
        type: 'plain',
        content: {
          value: '',
          type: 'single'
        }
      },
      barcode: {
        type: 'action',
        content: {
          value: '',
          type: 'single'
        }
      }
    },
    party: {
      joined: {
        type: 'plain',
        content: {
          value: '',
          value_en: '',
          type: 'single'
        }
      },
      current_roles: {
        type: 'plain',
        content: {
          value: {
            list: [],
            list_en: []
          },
          type: 'list'
        }
      },
      sub_organizations: {
        type: 'action',
        content: {
          value: {
            list: [],
            list_en: []
          },
          type: 'list'
        }
      },
      sponsorship: {
        type: 'action',
        content: {
          value: {
            list: [],
            list_en: []
          },
          type: 'list'
        }
      },
      donations: {
        type: 'action',
        content: {
          type: 'empty'
        }
      },
      sub_organizations_general: {
        type: 'modal-data',
        content: {
          value: [],
        }
      },
    },

    settings: {
      language: {
        type: 'edit',
        content: {
          value: {
            options: {
              is: 'Íslenska',
              en: 'Enska'
            },
            optionsEn: {
              is: 'Icelandic',
              en: 'English'
            },
            selectedOption: ''
          },
          type: 'radioButton'
        }
      },
      text_size: {
        type: 'edit',
        content: {
          value: {
            options: {
              s: 'Lítil',
              m: 'Millistærð',
              l: 'Stór'
            },
            optionsEn: {
              s: 'Small',
              m: 'Medium',
              l: 'Large'
            },
            selectedOption: ''
          },
          type: 'radioButton'
        }
      },
      privacyPolicy: {
        type: 'action',
        content: {
          value: '',
          type: 'single'
        }
      },
      communication: {
        type: 'action',
        content: {
          value: "",
          type: 'single'
        }
      },
      interests: {
        type: 'edit',
        content: {
          value: [],
          type: 'checkBox'
        }
      }
    },
    reasons_for_leaving: {},
    accepted_terms: false,
    deleted: false,
    sponsor: null,
    logged_out: false
  },
  eventState: {
    becomeSponsor: {
      id: 1,
      type: 'sponsorship',
      name: 'Become Sponsor',
      image: paymentBannerImg,
      title: '',
      contentPreview: '',
      textTitle: 'Thank you for waiting!',
      content: 'Lorem ipsum dolor si amet, consetetur sadipscing elistr',
      formTitle: 'Chose your monthly fee',
      forContent: 'You can change fee or cancel your sponsorship any time',
      options: ['1000kr', '3000kr', '5000kr', 'other']
    },
    generalEvent: {
      id: 2,
      type: 'general',
      name: 'Event Name',
      image: generalImage,
      title: 'Event name or headline should go right over here ',
      contentPreview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh neque, commodo ac auctor id, volutpat…',
      textTitle: 'Thank you for opening this Dialog!',
      content: 'Lorem ipsum dolor si amet, consetetur sadipscing elistr',
    },
    generalEvent2: {
      id: 3,
      type: 'general',
      name: 'Event Name',
      image: generalImage,
      title: '#3Event name or headline should go right over here ',
      contentPreview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh neque, commodo ac auctor id, volutpat…',
      textTitle: 'Thank you for opening this Dialog!',
      content: 'Lorem ipsum dolor si amet, consetetur sadipscing elistr',
    }
  },
  openedModal: {
    name: null,
    type: null
  },
  openSidebar: false,
  errorPopup: {
    showError: false,
    errorType: ''
  },
  showLoader: false,
  previousLocation: null
};

const [
  StoreProvider,
  useAppState,
  useAppDispatch,
  useAppApi
] = createStore(MyPagesApi, myPagesReducer, initialState);

export { StoreProvider, useAppState, useAppDispatch, useAppApi };