import React, { useState } from 'react';
import Image from './Image';
import './EditImage.scss';
import { useTranslation } from "react-i18next";
import { useAppApi } from '../store';


/**
 * EditImage component
 * 
 * @param {object} param - destructured props object
 * @param {object} param.content - field content
 * @param {boolen} param.shouldEdit - used to determine whether or not editable content shoud be shown
 * @param {function} param.hideEditableContent - hides editable content
 * @param {string} param.message - edit message
 * 
 * @returns {JSX Element}
 */
function EditImage ({ content, shouldEdit, hideEditableContent, message, onEdit, updateMiddleware }) {

  const { t } = useTranslation();
  const [ profileImage, setProfileImage ] = useState(content.value);

  const { showErrorPopup, hideErrorPopup, uploadImage } = useAppApi();


  /**
   * Handles filed close
   */
  const onClose = () => {
    hideEditableContent()
    hideErrorPopup();
  }

  /**
   * Checks if selected file type is allowed
   * 
   * @param {string} fileName 
   * @returns {boolean}
   */
  const checkFileType = fileName => {
    const allowedTypes = ['jpg', 'jpeg', 'png']
    return allowedTypes.indexOf(fileName.toLowerCase().split('.').pop()) > -1
  }


  /**
   * Handles input change
   */
  const onInputChange = async e => {
    // to access an event in an asynchronous way, we need to take the
    // SyntheticEvent out of the pool and prevent it from being reclaimed by React(https://reactjs.org/docs/events.html#event-pooling).
    e.persist()

    const resetInput = () => e.target.value = null;

    if(!checkFileType(e.target.value)) {
      showErrorPopup('fileType')
      resetInput()
      return
    }
    if(e.target.files[0].size <= 2097152) {
      hideErrorPopup();
      const formData = new FormData();
      formData.append('profile_image', e.target.files[0]);
      formData.append('old_pathname', profileImage)

      try {
        const response = await uploadImage(formData)

        if(!response.ok) {
          const message = `An error has occured: ${response.status}`
          throw new Error(message)
        }

        const data = await response.json()

        if(data.updated_member && data.updated_member.profile_image) {
          setProfileImage(data.updated_member.profile_image)
          onEdit(data.updated_member.profile_image)
          updateMiddleware(data.updated_member.profile_image)
          hideEditableContent()
        }

      } catch (e) {
        showErrorPopup('network')
        resetInput()
        console.error(e)
      }
    } else {
      showErrorPopup('imageSize')
      resetInput()
    } 
  };

  if(shouldEdit) {
    return (
      <div className="edit-image">
        <Image value={profileImage} />
        <span className='edit-image__message'>{message}</span>
        <form >
          <label htmlFor="profile_image" className="edit-image__upload">
            {t('buttons.upload')}
          </label>
          <input id="profile_image" name='profile_image' type="file" max="2000" accept=".png, .jpg, .jpeg" onChange={e => onInputChange(e)} />
        </form>
        <button className="edit-image__close" onClick={onClose}>{t('buttons.cancel')}</button>
      </div>
    )
  }
  return <Image value={content.value} />
  
}

export default EditImage;
