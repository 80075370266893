import React, { createContext, useContext, useReducer, useMemo } from 'react';

/**
 * Generic api factory hook
 *
 * @param {function}api
 * @param {function}reducer
 * @param {object} initialState
 * @param {function|undefined}initFn
 * @return {any}
 */
const useReducerApi = (api, reducer, initialState, initFn = undefined) => {
  const [ state, dispatch ] = useReducer(reducer, initialState, initFn);
  return useMemo(() => api(state, dispatch), [ state, dispatch, api ]);
};

/**
 * Store factory
 *
 * @param {function} api
 * @param {function} reducer
 * @param {object} initialState
 * @return {array<ReactElement|function>}
 */
function createStore(api, reducer, initialState) {
  const StateContext = createContext(null);
  const DispatchContext = createContext(null);
  const ApiContext = createContext(null);

  /**
   * Store provider component
   *
   * @param {object} props
   * @return {ReactElement}
   */
  const StoreProvider = props => {
    const [ state, dispatch, appApi ] = useReducerApi(api, reducer, initialState);

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <ApiContext.Provider value={appApi}>
            {props.children}
          </ApiContext.Provider>
        </DispatchContext.Provider>
      </StateContext.Provider>
    );
  };

  /**
   * Retrieves the latest app state
   *
   * @return {object}
   */
  const useAppState = () => {
    return useContext(StateContext);
  };
  /**
   * Retrieves app dispatch
   *
   * @return {object}
   */
  const useAppDispatch = () => {
    return useContext(DispatchContext);
  }
  /**
   * Retrieves app api
   *
   * @return {object}
   */
  const useAppApi = () => {
    return useContext(ApiContext);
  };

  return [ StoreProvider, useAppState, useAppDispatch, useAppApi ];
}

export default createStore;