import React, {useState, useEffect} from 'react';
import RadioOrCheckInput from './RadioOrCheckInput';
import RadioButtonValue from './RadioButtonValue';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import { useAppState, useAppApi } from '../store';
import './EditRadioButton.scss';
import { useTranslation } from 'react-i18next';

/**
 * EditRadioButton component
 * 
 * @param {object} param - destructured props object
 * @param {string} param.title - field title
 * @param {object} param.content - field content
 * @param {boolean} param.shouldEdit - used to determine whether or not editable content shoud be shown
 * @param {function} param.hideEditableContent - hides editable content
 * @param {function} param.onEdit - handles edited data
 * @param {string} param.message - edit message
 * @param {function} param.updateMiddleware - prepared fetch function
 * 
 * 
 * @returns {JSX Element}
 */
function EditRadioButton ({ content, shouldEdit, hideEditableContent, onEdit, changeEditedFieldStatus, message, updateMiddleware}) {
  /**
   * Creates initial state
   * 
   * @param {object} data 
   * @param {string} currentLang 
   */
  const createInitialState = (data, currentLang) => {
    let preparedArray = [];
    const options = currentLang === 'is' ? data.options : data.optionsEn;

    Object.entries(options).forEach( ([key, value]) => {
      preparedArray.push({
        name: value,
        checked: key === data.selectedOption,
        optionValue: key
      })
    });
    return preparedArray;
  }

  const { i18n } = useTranslation();
  let currentLang = i18n.language;

  const [ state, setState ] = useState([]);

  const [ saveEnabled, setSaveEnabled ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState('');
  const [ showLoader, setShowLoader ] = useState(false);


  const { currentEditingField } = useAppState();
  const { edited, initialValue } = currentEditingField;

  const { showErrorPopup, hideErrorPopup } = useAppApi();


  /**
   * Handles input element change
   *  
   * @param {event} e 
   */
  const onInputChange = (e) => {
    let preparedOptions = state.map(element => {
      if(element.name === e.target.value) {
        element = {...element, checked: e.target.checked}
        setSelectedOption(element.optionValue)
      } else {
        element = {...element, checked: false}
      }
      return element;
    });

    setState(preparedOptions);

    if(!edited) {
      changeEditedFieldStatus(true);
    }
    
    if(!saveEnabled) {
      setSaveEnabled(true);
    }
  }

  /**
   * Change component state based on middleware response
   * 
   * @param {object} response 
   */
  const handleStateOnMiddlewareUpdate = response => {
    if(response && response.ok) {
      hideEditableContent();
      setSaveEnabled(false);
    } else {
      showErrorPopup('network')
    }
    setShowLoader(false)
  }

  /**
   * Handles changed data save
   */
  const onChangeSave = () => {
    setShowLoader(true)
    hideErrorPopup();
    updateMiddleware(selectedOption)
      .then(response => {
        handleStateOnMiddlewareUpdate(response)
        onEdit({
          ...content.value,
          selectedOption: selectedOption
        });
      })
      .catch(e => {
        console.log(e)
        handleStateOnMiddlewareUpdate()
      })
  }

  /**
   * Close editing field and dispatch field initial value
   * 
   * @param {array} initialValue 
   */
  const onCancel = (initialValue) => {
    //reset changes
    setState(createInitialState(initialValue, currentLang))
    hideEditableContent();
    hideErrorPopup();
  }
  
  useEffect(() => {
    setState(createInitialState(content.value, currentLang));
  }, [currentLang, content.value]);
  
  if(shouldEdit) {
    return (
      <div className="edit-radio-button">
        <p className='edit-radio-button__message'>{message}</p>
        <ul className="edit-radio-button__list">
        {
          state.map((item, index) => {
            return (
              <RadioOrCheckInput
                key={index}
                type='radio'
                name='edit-radio-button-item'
                value={item.name}
                checked={item.checked}
                onInputChange={onInputChange}
                className="edit-radio-button__list-item"
              />
            )
          })
        }
        </ul>
        <div>
          <SaveButton onClick={onChangeSave} enableSave={saveEnabled} showLoader={showLoader} />
          <CancelButton onClick={() => onCancel(initialValue)} />
        </div>
      </div>
    )
  }
  return <RadioButtonValue value={state} />
}

export default EditRadioButton;