import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../components/Home';
import { useAppState } from '../store';



function AuthRoute({component: Component, ...rest}) {

  const currentState = useAppState();
  const { user: { accepted_terms, logged_out}} = currentState;

  return (
    <Route
      { ...rest}
      render={props => {
        if(accepted_terms && !logged_out) {
          return <Component {...props}/>;
        }
        return <Home {...props}/>
      }
    }/>
  );
}

export default AuthRoute;