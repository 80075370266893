import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppApi } from '../store';
import { useAppState, useAppDispatch} from '../store';
import { useLocation } from 'react-router-dom';




function NavigationItem(props) {
  const { className, url } = props;

  const location = useLocation()
  const dispatch = useAppDispatch();
  


  const { promptConfirmBoxOnRoutChange } = useAppApi();
  const { currentEditingField } = useAppState();
  const { editedFieldName, edited, initialValue, screen } = currentEditingField;

  return (
    <div className={className + ' menu-item'}>
      <NavLink
        exact={true} to={url}
        onClick={(e) => {
            promptConfirmBoxOnRoutChange(e, editedFieldName, edited, initialValue, screen)
            dispatch({ type: "SET_PREVIOUS_PATH", payload: { path: location.pathname } });
          }
        }
        className={className + '--link'}
        activeClassName="active-menu-item"
      >
        {props.children}
      </NavLink>
    </div>
  )
}
export default NavigationItem;
