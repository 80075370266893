import React from 'react';
import ActionButton from './ActionButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store';


import './LeaveParty.scss'
import image from './images/group-5.png'

export default function LeaveParty() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const toggleModal = () => dispatch({ type: 'TOGGLE_MODAL', modalName: 'leave' })


  return (
    <div className='leave-party'>
      <div className='leave-party__inner-wrapper'>
        <div className='leave-party__content'>
          <img src={image} alt="leave" className='leave-party__image' />
          <p className='leave-party__text'>{t('leavePartyContent.text')}</p>
        </div>
        <ActionButton
          onClick={toggleModal}
          buttonText={t('buttons.leaveParty')} />
      </div>
    </div>
  )
}
