import React from 'react';
import avatar from './images/avatar.png';
import './Image.scss';

/**
 * Image component
 * 
 * @param {object} param - destructured props object
 * @param {string} param.value - image URL path
 * @returns {JSX Element}
 */
function Image ({ value }) {
  
  return (
    <div style={{ 'backgroundImage': `url(${value ? value : avatar})` }} className='image-content'></div>
  );
}

export default Image;