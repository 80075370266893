import { useEffect } from 'react';
import { useAppState, useAppDispatch, useAppApi } from '../store';

/**
 * Home component
 * 
 * Used for handling logic on '/' route
 * 
 * @param {*} props 
 */
function Home(props) {
  const params = new URLSearchParams(props.location && props.location.search)
  const shouldRedirectToRegister = params.has('new_member');

  const currentState = useAppState();
  const user = currentState.user;
  const dispatch = useAppDispatch();
  const { fetchUserData, logoutUser } = useAppApi();

  console.log('Home component');

  useEffect(() => {
    //check if search params exist and if so redirect to Register page
    if (shouldRedirectToRegister) {
      fetchUserData('/register')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.statusText}`)
          }
          return response.json();
        })
        .then(data => {
          props.history.push({
            pathname: '/register',
            state: { data }
          });
        })
        .catch(err => {
          console.log(err);
          window.location.href = 'https://xd.is/';
        });
    } else {
      //check if user data is in local state
      if (user.profile.full_name.content.value) {
        //if in local state check if it is a first time login
        if (!user.accepted_terms) {
          //if first time login redirect to Terms Form Screen
          props.history.push({
            pathname: '/terms',
          });
        } else {
          // if session is expired and user is logged out and redirected to session expired page
          // we want to prevent him from accessing other routes
          if (user.logged_out) {
            window.location.href = 'https://xd.is/';
            return
          }
          //else redirect to /profile
          props.history.push({
            pathname: '/profile',
          });
        }
      } else {
        // else render loader and make GET request to middleware /me endpoint
        dispatch({ type: "SHOW_LOADER", payload: { show: true } });
        // disppatch above will triger re render so to prevent fetching data twice 
        // we only fetch if currentState.showLoader === false
        if (!currentState.showLoader) {
          fetchUserData('/me')
            .then(response => {
              console.log("response", response)
              if (!response.ok) {
              console.log("!response.ok", response)
                // if case someone in with teporary acces token(user not registered)
                // tries to fetch data from '/me' route, we logout that user
                if (response.status === 403) {
                  logoutUser()
                }
                throw new Error(`${response.statusText}`)
              }
              return response.json()  //we only get here if there is no error
            })
            .then(data => {
              if (data) {
                dispatch({ type: 'ADD_USER_DATA', payload: data.member.data })
                dispatch({ type: "SHOW_LOADER", payload: { show: false } });
              }
            })
            .catch(err => {
              console.log(err)
              // redirect user to xd.is
              window.location.href = 'https://xd.is/';
            })
        }
      }
    }
  });


  return null;
}

export default Home;