import React from 'react'
import RadioOrCheckInput from './RadioOrCheckInput';
import { useTranslation } from "react-i18next";

import './ChoiceBox.scss'

/**
 * This is componennt which contain checkboxes or radio buttons with title and subtitle above it. 
 * @param {Intiger} key - Index number 
 * @param {String} title - Title of the component
 * @param {String} subtitle - Subtitle of the component
 * @param {Array} options - Array with values which will be rendered as checkbox or radio buttons
 * @param {String} type - This will determine if this will render checkbox or radio button
 * @param {String} errorClassName - in registration form component we have to valid this fields, so if there is need for this we can setup error class
 * @param {Boolean} isRequired - is field required or not. Need for registration form 
 * @param {function} onInputChange - handler for button onClick event 
 */
export default function ChoiceInputWithTitle({ title, subtitle = '', options, type, isRequired, errorClassName, onInputChange, isFormSubmited, hasValidation = false }) {

  const { t } = useTranslation();
  return (
    <div className={`choice-box ${isRequired ? errorClassName : ''}`} >
      <span className="choice-box__title"><p>{t(`${title}`)} {!isRequired ? <span className="choice-box__optional">  - {t('optional')}</span> : ''}</p> </span>
      {<p className="choice-box__sub-title">{t(`${subtitle}`)}</p>}
      {options.map((option, index) =>
        <RadioOrCheckInput
          value={option}
          name={title}
          type={type}
          isRequired={isRequired}
          onInputChange={onInputChange(option)}
          key={index}
        />
      )}
    </div>
  )
}
