import React, {useState} from 'react';
import RadioOrCheckInput from './RadioOrCheckInput';
import CheckBoxList from './CheckBoxList';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import { useAppState, useAppApi } from '../store';
import { useTranslation } from 'react-i18next';
import './EditCheckBoxList.scss';



/**
 * EditCheckBoxList
 * 
 * @param {object} param - destructured props object
 * @param {string} param.title - field title 
 * @param {object} param.content - field content 
 * @param {boolean} param.shouldEdit - used to determine whether or not editable content shoud be shown
 * @param {function} param.hideEditableContent - hides editable content 
 * @param {function} param.onEdit - dispatch function
 * @param {string} param.message - edit message
 * @param {function} param.updateMiddleware - prepared fetch function
 * 
 * @returns {JSX Element}
 */
function EditCheckBoxList ({ content, shouldEdit, hideEditableContent, onEdit, changeEditedFieldStatus, message, updateMiddleware}) {

  const { i18n } = useTranslation();
  let currentLang = i18n.language;

  const [ saveEnabled, setSaveEnabled ] = useState(false);

  const { currentEditingField } = useAppState();
  const { edited, initialValue } = currentEditingField;

  const { showErrorPopup, hideErrorPopup } = useAppApi();
  const [ showLoader, setShowLoader ] = useState(false);



  /**
   * Handles input change
   * 
   * @param {event} e 
   */
  const onInputChange = (e) => {
    let preparedOptions = content.value.map(element => {
      const optionName = currentLang === 'is' ? element.name : element.name_en;
      if(optionName === e.target.value) {
        element = {...element, checked: e.target.checked}
      }
      return element;
    });
    onEdit(preparedOptions);

    if(!edited) {
      changeEditedFieldStatus(true);
    }

    if(!saveEnabled) {
      setSaveEnabled(true);
    }
  };

  /**
   * Change component state based on middleware response
   * 
   * @param {object} response 
   */
  const handleStateOnMiddlewareUpdate = response => {
    if(response && response.ok) {
      hideEditableContent();
      setSaveEnabled(false);
    } else {
      showErrorPopup('network')
    }
    setShowLoader(false)
  }

  /**
   * Handles changed data save
   */
  const onChangeSave = () => {
    setShowLoader(true)
    hideErrorPopup()
    updateMiddleware(content.value)
      .then(response => {
        handleStateOnMiddlewareUpdate(response)
      })
      .catch(e => {
        console.log(e)
        handleStateOnMiddlewareUpdate()
        showErrorPopup('network')
      })
  }

  /**
   * Close editing field and dispatch field initial value
   * 
   * @param {array} initialValue 
   */
  const onCancel = (initialValue) => {
    onEdit(initialValue);
    hideEditableContent();
    hideErrorPopup();
  }


  if(shouldEdit) {
    return (
      <div className="edit-checkbox">
        <p className='edit-checkbox__message'>{message}</p>
        <ul className="edit-checkbox__list">
        {
          content.value.map((item, index) => {
  
            return (
              <RadioOrCheckInput
                key={index}
                type='checkbox'
                name='edit-checkbox-item'
                value={currentLang === 'is' ? item.name : item.name_en}
                checked={item.checked}
                onInputChange={onInputChange}
                className="edit-checkbox__list-item"
              />
            )
          })
        }
        </ul>
        <div className="edit-checkbox__buttons">
          <SaveButton onClick={onChangeSave} enableSave={saveEnabled} showLoader={showLoader} />
          <CancelButton onClick={() => onCancel(initialValue)} />
        </div>
      </div>
    )
  }
  return <CheckBoxList value={content.value} />
  
}

export default EditCheckBoxList;