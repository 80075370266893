import React from 'react';
import Field from './Field';
import EditText from './EditText';
import EditImage from './EditImage';
import EditRadioButton from './EditRadioButton';
import EditCheckBoxList from './EditCheckBoxList';
import EditButton from './EditButton';
import { useAppApi } from '../store';
import { useAppState } from '../store';
import { useTranslation } from 'react-i18next';
import './EditableField.scss';


/**
 * EditableField component
 * 
 * @param {object} param - destructured props object
 * @param {object} param.data - field data
 * @param {function} param.onEdit - handles edited data
 * @param {string} param.screen - possible values: profile, party, settings
 * @param {string} param.title - field title
 * @param {string} param.message - edit message
 * 
 * @returns {JSX Element}
 */
function EditableField ({ data, onEdit, fieldName, screen, title, message }) {

  const { 
    changeEditedFieldName, 
    changeEditedFieldStatus, 
    changeEditedFieldInitialValue,
    changeEditedFieldScreen,
    promptConfirmBoxOnFieldChange,
    updateMiddlewareOnSaveChange
  } = useAppApi();
  const { currentEditingField } = useAppState();
  const { editedFieldName } = currentEditingField;
  const { t } = useTranslation();

  const shouldEdit = fieldName === editedFieldName;
  
  /**
   * Shows editable content
   */
  const showEditableContent = ( editedFieldName, edited, initialValue) => {

    if(fieldName !== editedFieldName && !edited) {
      changeEditedFieldName(fieldName);
      changeEditedFieldInitialValue(data.content.value);
      changeEditedFieldScreen(screen);
    } else {
      promptConfirmBoxOnFieldChange(editedFieldName, edited, initialValue, screen);
    }
  }

  /**
   * Hides editable content
   */
  const hideEditableContent = () => {
    changeEditedFieldName('');
    changeEditedFieldStatus(false)
  }

  /**
   * Returns react component based on field content type
   * 
   * @param {object} data - field data
   * @returns {JSX Element}
   */
  const renderEditableContent = (data) => {
    switch (data.content.type) {
      case 'image':
        return <EditImage
                  content={data.content} 
                  shouldEdit={shouldEdit} 
                  hideEditableContent={hideEditableContent} 
                  onEdit={onEdit}
                  changeEditedFieldStatus={changeEditedFieldStatus}
                  message={message}
                  updateMiddleware={updateMiddlewareOnSaveChange(screen, fieldName)} 
                />
      case 'single':
        return <EditText 
                  title={title} 
                  content={data.content} 
                  shouldEdit={shouldEdit} 
                  hideEditableContent={hideEditableContent} 
                  onEdit={onEdit}
                  changeEditedFieldStatus={changeEditedFieldStatus}
                  fieldName={fieldName}
                  message={message}
                  updateMiddleware={updateMiddlewareOnSaveChange(screen, fieldName)}            
                />
      case 'radioButton': 
        return <EditRadioButton 
                  title={title} 
                  content={data.content} 
                  shouldEdit={shouldEdit} 
                  hideEditableContent={hideEditableContent}
                  onEdit={onEdit}
                  changeEditedFieldStatus={changeEditedFieldStatus}
                  message={message}
                  updateMiddleware={updateMiddlewareOnSaveChange(screen, fieldName)}                
                />
      case 'checkBox': 
        return <EditCheckBoxList 
                  title={title} 
                  content={data.content} 
                  shouldEdit={shouldEdit} 
                  hideEditableContent={hideEditableContent} 
                  onEdit={onEdit}
                  changeEditedFieldStatus={changeEditedFieldStatus}
                  message={message}
                  updateMiddleware={updateMiddlewareOnSaveChange(screen, fieldName)}
                />
      default:
        return null;
    }
  }


  /**
   * Prepares button text based on field content type and value
   * 
   * @param {string} fieldType
   * @param {any} fieldValue
   * @returns {string}
   */
  const prepareButtonText = (fieldType, fieldValue) => {
    let emptyField = false;

    if(fieldType === 'single' && fieldValue === ''){
      emptyField = true;
    } else if(fieldType === 'checkBox') {
      const checkedOption = fieldValue.find(item => item.checked === true);
      if(!checkedOption) {
        emptyField = true;
      }
    }

    return !emptyField ? t('buttons.edit') : '+ ' + t('buttons.add');
  }

  return (
    <div className={`editable-field ${shouldEdit ? 'editable-field--active' : ''} editable-field--${data.content.type}`}>
      <Field title={title} subtitle={t(`optionalFiledsText.${fieldName}`, '')}>
          <div className="editable-field__content">
            { renderEditableContent(data) }
          </div>
          <EditButton active={shouldEdit} onClick={showEditableContent}>
            {prepareButtonText(data.content.type, data.content.value)}
          </EditButton>
      </Field>
    </div>
  );

}

export default EditableField;