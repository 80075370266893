import React from 'react';
import { useAppState } from '../store';
import './EditButton.scss'; 



/**
 * EditButton component
 * 
 * @param {object} param - destructured props object
 * @param {boolean} active 
 * @param {function} onClick - handler for button onClick event
 * 
 * @returns {JSX Element}
 */
const EditButton = ({ active, onClick, children }) => {

  const { currentEditingField } = useAppState();
  const { editedFieldName, edited, initialValue } = currentEditingField;

  return (
    <button
      className={`edit-button ${active ? 'edit-button--active' : ''}`}
      onClick={() => onClick( editedFieldName, edited, initialValue)}
    >
      {children}
    </button>
  )
}

export default EditButton;