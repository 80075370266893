import React, { useEffect } from 'react';
import { useAppState, useAppDispatch } from '../store';
import ModalPayment from './ModalPayment';
import ModalForm from './ModalForm';

import { TOGGLE_MODAL } from '../store/actions';

import ModalLeaveParty from './ModalLeaveParty';
import ModalPrivacyPolicy from './ModalPrivacyPolicy';
import ModalTermsAndConditions from './ModalTermsAndConditions';
import ModalChangeCommunication from './ModalChangeCommunication';
import ModalSponsorship from './ModalSponsorship';
import ModalWelcome from './ModalWelcome';
import ModalGeneralSubOrganizations from './ModalGeneralSubOrganizations';
import ModalSessionAboutToExpire from './ModalSessionAboutToExpire';
import ModalBarcode from './ModalBarcode';

/**
 * Component
 * renders proper modal if some modal is opened
 *
 * @param {object} props
 * @return {ReactElement}
 */
function ModalWrapper(props) {

  const { user, openedModal, eventState } = useAppState();
  const dispatch = useAppDispatch();
  let Modal;

  /*
  * Keyboard actions
  * Esc (27 keycode) close modal
  * Enter (13 keycode) submit the form
  */
  function modalKeyPressFn(e) {
    let kcode = e.keyCode;

    switch (kcode) {
      case 27:
        dispatch({ type: TOGGLE_MODAL, modalName: 'closeModal' });
        break;
      case 13:
        console.log('Submit form');
        break;
      // no default
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', modalKeyPressFn);
    return () => {
      window.removeEventListener('keydown', modalKeyPressFn);
    };
  })
  /** 
  * Check which modal is chosen by user
  * 
  * @param string
  * 
  */
  switch (openedModal.name) {
    case 'payment':
      Modal = ModalPayment;
      break;
    case 'general':
      Modal = ModalForm;
      break;
    case 'leave':
      Modal = ModalLeaveParty;
      break;
    case 'privacy':
      Modal = ModalPrivacyPolicy;
      break;
    case 'terms':
      Modal = ModalTermsAndConditions;
      break;
    case 'communicationModal':
      Modal = ModalChangeCommunication;
      break;
    case 'sponsorship':
      Modal = ModalSponsorship;
      break;
    case 'welcome':
      Modal = ModalWelcome;
      break;
    case 'sub_organizations':
      Modal = ModalGeneralSubOrganizations;
      break;
    case 'session_expired':
      Modal = ModalSessionAboutToExpire;
      break;
    case 'barcode':
      Modal = ModalBarcode;
      break;
    default:
      Modal = null;
  }

  return (
    <div className={openedModal.name === null || openedModal.name === 'closeModal' ? 'modal' : 'modal modal--open'}>
      {Modal && <Modal eventData={eventState} userData={{ ...user }} type={openedModal.type} />}
    </div>
  );
}

export default ModalWrapper;