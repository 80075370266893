import React from 'react';
import { useAppDispatch } from '../store';
import './ModalPrivacyPolicy.scss';


/**
 * ModalPrivacyPolicy component
 * 
 * @returns {JSX Element}
 */
function ModalPrivacyPolicy() {

  const modalDispatch = useAppDispatch();

  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null});

  return (
    <div className="modal-wrapper">
      <div className="privacy-policy">
        <button className="privacy-policy__close" onClick={closeModal}></button>

        <h2>PERSÓNUVERNDARSTEFNA SJÁLFSTÆÐISFLOKKSINS</h2>

        <h3>1. UM PERSÓNUVERNDARSTEFNUNA</h3>

        <p>Sjálfstæðisflokkurinn tekur alvarlega réttindi einstaklinga er varða persónuupplýsingar þeirra og leggur sérstaka áherslu á að þær séu unnar með lögmætum, sanngjörnum og gagnsæjum hætti. </p>

        <p>Persónuverndarstefnu þessari er ætlað að vera upplýsandi um það hvaða persónuupplýsingum flokkurinn aflar og í hvaða tilgangi það er gert, hvernig þær eru varðveittar, með hvaða hætti flokkurinn nýtir slíkar upplýsingar, hvert upplýsingum kunni að vera miðlað og með hvaða hætti gætt sé að öryggi þeirra.</p>

        <h3>2. UPPLÝSINGAR UM ÁBYRGÐARAÐILA</h3>
        
        <p>Sjálfstæðisflokkurinn, Háaleitisbraut 1, 105 Reykjavík, kt. 570269-1439, er ábyrgðaraðili þeirra persónuupplýsinga sem veittar eru flokknum og flokkurinn hefur aflað sér. Sjálfstæðisflokkurinn er stjórnmálaflokkur, þ.e. frjáls félagasamtök, sem stofnaður var um tiltekin stefnumál, hugmyndafræði og skoðanir, en ein forsendum lýðræðis er að frjáls starfsemi um stjórnmálaskoðanir fái að þrífast. Af þeirri ástæðu njóta stjórnmálaflokkar stjórnskipulegrar verndar sem sérstaklega mikilvægar félagagerðir í lýðræðisríki. Það er nauðsynlegt fyrir stjórnmálasamtök að halda utan um persónuupplýsingar, m.a. í þeim tilgangi að miðla upplýsingum til flokksfélaga og eftir atvikum annarra. Sjálfstæðisflokkurinn hefur á að skipa persónuverndarfulltrúa sem hægt er að hafa samband við með því að senda tölvupóst á personuvernd@xd.is eða bréflega á framangreint heimilisfang.</p>

        <h3>3. HVAÐ ERU PERSÓNUUPPLÝSINGAR?</h3>
        <p>Persónuupplýsingar í skilningi stefnu þessarar eru hvers kyns upplýsingar um persónugreindan eða persónugreinanlegan einstakling, þ.e. upplýsingar sem hægt er að rekja beint eða óbeint til ákveðins einstaklings.</p>

        <h3>4. ÖFLUN PERSÓNUUPPLÝSINGA OG TILGANGUR</h3>
        <p>Sjálfstæðisflokkurinn aflar og vinnur með persónuupplýsingar um flokksmenn, starfsmenn og þá sem flokkurinn á í viðskiptasambandi við. Flokkurinn safnar einungis þeim upplýsingum sem nauðsynlegar eru til að flokkurinn geti sinnt skyldum sínum gagnvart framangreindum aðilum. </p>
        <p>Að meginstefnu til aflar Sjálfstæðisflokkurinn persónuupplýsinga beint frá flokksmönnum, starfsmönnum eða þeim sem hann á í viðskiptasambandi við. Upplýsingar kunna þó jafnframt að koma frá þriðju aðilum, t.d. stjórnvöldum og fyrirtækjum.</p>
        <p>Til að tryggja áreiðanleika þeirra upplýsinga sem flokkurinn varðveitir eru þær uppfærðar eftir þörfum, t.d. með því að samkeyra upplýsingar við þjóðskrá.</p>
        <p>Ólíkum upplýsingum kann að vera aflað eftir því hvers eðlis samband viðkomandi er við flokkinn. Hér að neðan má nálgast yfirlit yfir það hvaða upplýsingar flokkurinn kann að afla og í hvaða tilgangi þær kunna að vera varðveittar.</p>
        <p>Það á við um þá kafla sem hér fara á eftir að upplýsingum um nöfn, kennitölu, heimilisföng, netföng og símanúmer er ekki deilt með þriðja aðila, sjá nánar neðar í kafla um meðhöndlun flokksskrár.</p>

        <h3>NAFN</h3>
        <p>Upplýsingarnar eru notaðar til að halda utan um nöfn þeirra sem aðild eiga að flokknum og einstökum félögum hans. Einnig til að halda utan um þá sem eru í styrktarmannakerfi, greiða félagsgjöld sem og þá sem gegna trúnaðarstörfum fyrir flokkinn. Nöfn, heimilisföng og kennitölur eru notuð til að halda utan um þá sem búa í hverju sveitarfélagi og kjördæmi og eiga m.a. rétt til setu á félagsfundum, njóta kjörgengi í viðkomandi félögum og ráðum og rétt til þátttöku í prófkjörum í aðdraganda sveitarstjórnarkosninga og alþingiskosninga.</p>

        <h3>KENNITALA</h3>
        <p>Upplýsingarnar eru einkum nýttar til þess að aðgreina þá sem bera sömu nöfn hvern frá öðrum. Kennitölur eru jafnframt notaðar til að afla upplýsinga um aldur og kyn flokksfélaga. Auk þess eru þær notaðar til að sækja mánaðarlega uppfærslu í þjóðskrá um lögheimili og aðrar upplýsingar sem þjóðskrá geymir. Kennitölur eru jafnframt nýttar til þess að fá upplýsingar um hverjir eru bannmerktir í þjóðskrá og/eða símaskrá og eins til að finna símanúmer hjá þeim sem ekki eru bannmerktir í aðdraganda kosninga. Nöfn, heimilisföng og kennitölur eru notuð til að halda utan um þá sem búa í hverju sveitarfélagi og kjördæmi og eiga þá rétt til setu á félagsfundum, njóta kjörgengi í viðkomandi félögum og rétt þátttöku í prófkjörum í aðdraganda sveitarstjórnarkosninga og alþingiskosninga og eftir atvikum aðild að einstökum félögum og ráðum, en þau ná hvert fyrir sig yfir mismunandi landfræðileg svæði - hverfi, sveitarfélög og/eða kjördæmi.</p>

        <h3>HEIMILISFANG OG PÓSTNÚMER</h3>
        <p>Upplýsingar um heimilisfang og póstnúmer eru nýttar til þess að skrá flokksfélaga í rétt flokksfélög innan flokksins, þ.e. þau flokksfélög og ráð sem starfrækt eru í því sveitarfélagi/póstnúmeri þar sem viðkomandi býr. Þá eru upplýsingar um heimilisfang og póstnúmer jafnframt notaðar til að koma áleiðis upplýsingum í pósti varðandi flokksstarfið. Nöfn, heimilisföng og kennitölur eru notuð til að halda utan um þá sem búa í hverju sveitarfélagi og kjördæmi og eiga m.a. rétt til setu á félagsfundum, njóta kjörgengi í viðkomandi félögum og ráðum og rétt til þátttöku í prófkjörum í aðdraganda sveitarstjórnarkosninga og alþingiskosninga. </p>

        <h3>NETFANG</h3>
        <p>Netföng eru notuð í þeim tilgangi að senda upplýsingar til flokksfélaga, sem geta verið af hvaða tagi sem er og varðað t.d. viðburði, fundi, skilaboð, fjáröflum, stefnumál auk ýmissa hluta í tengslum við kosningar o.fl. Haldið er utan um netföng þeirra sem veitt hafa fyrir því heimild.</p>

        <h3>SÍMANÚMER</h3>
        <p>Símanúmer eru notuð í þeim tilgangi að miðla upplýsingum til flokksfélaga, sem geta verið af hvaða tagi sem er og varðað t.d. viðburði, fundi, skilaboð, fjáröflun, stefnumál auk ýmissa hluta í tengslum við kosningar o.fl. Haldið er utan um símanúmer þeirra sem veitt hafa fyrir því heimild. </p>

        <h3>TILGANGUR</h3>
        <p>Megintilgangurinn með öflun og vinnslu persónuupplýsinga er að flokkurinn geti efnt skyldur sínar og rækt hlutverk sitt gagnvart flokksmönnum, starfsmönnum og þeim sem hann á í viðskiptasambandi við. Persónuupplýsingar í vörslu Sjálfstæðisflokksins eru ekki nýttar í öðrum tilgangi en þeim sem þeirra var aflað til.</p>
        <p>Sjálfstæðisflokknum er annt um að koma stefnumálum og starfsemi sinni á framfæri við fólk. Slík miðlun upplýsinga byggir á því að geta átt í samskiptum við fólk. Sú öflun persónuupplýsinga sem snýr að félagsmönnum tekur einkum mið af því að flokkurinn geti sinnt því hlutverki sínu að koma upplýsingum áleiðis og auðvelda flokksmönnum þátttöku í starfi flokksins.</p>

        <h3>5. MEÐHÖNDLUN FLOKKSSKRÁR</h3>
        <p>Aðild að Sjálfstæðisflokknum fer fram gegnum þau fjölmörgu félög sem aðild eiga að flokknum um land allt. Félagssvæðin eru misjöfn, allt frá því að vera eitt hverfi í sveitarfélagi yfir í að ná yfir nokkur sveitarfélög, eða hluta sveitarfélaga. Sjá nánar hér í skipulagsreglum Sjálfstæðisflokksins. Sjálfstæðisflokkurinn heldur úti miðlægri flokksskrá þar sem varðveittar eru upplýsingar um alla flokksmenn, í hvaða félagi hver og einn flokksmaður starfar, réttindi og trúnaðarstörf viðkomandi fyrir flokkinn.</p>
        <p>Aðgang að flokksskránni í heild hafa einungis þeir starfsmenn Sjálfstæðisflokksins sem vegna starfa sinna þurfa þess og er henni ekki deilt með þriðja aðila, sjá nánar í kafla um miðlun persónuupplýsinga hér að neðan.</p>
        <p>Í miðlægri flokksskrá er haldið utan um upplýsingar um félagatal hvers félags og ráðs. Aðgang að slíku félagatali hafa einungis formenn þeirra og stjórnir í lögmætum tilgangi til þess að geta rækt skyldur við félagsmenn, m.a. vegna aðal- og félagsfunda auk prófkjara. Á það eftir atvikum líka við um flokksmenn í sérstökum trúnaðarstörfum eins og fundarstjóra og starfsmenn félagsfunda auk kjör- og uppstillinganefnda í tengslum við val á listum flokksins. Stjórnir félaga geta veitt ákveðnum hópi félagsmanna takmarkaðan aðgang að félagatali, í húsnæði á vegum flokksins, til að miðla upplýsingum til annarra félagsmanna, t.a.m. í aðdraganda alþingis- og sveitarstjórnarkosninga. Þegar slík skrá er útbúin koma fram kennitölur, nöfn, heimilisföng, póstnúmer og eftir atvikum símanúmer allra sem aðild eiga að félagi/ráði. Félög og ráð fá ekki afhent netföng úr flokksskrá, en skrifstofa flokksins sendir út skilaboð í tölvupósti á starfssvæði hvers félags og ráðs eftir þörfum hverju sinni. Slíkur aðgangur að félagatali er einungis veittur gegn undirritun strangrar trúnaðarskuldbindingar um meðferð þess og að meðferð þess samræmist í einu og öllu reglum flokksins og lögum landsins.</p>
        <p>Í tengslum við prófkjör, röðun og kosningar til trúnaðarstarfa til ráða og félaga flokksins geta frambjóðendur í þeim fengið aðgang að kjörskrá, þ.e. viðkomandi félagatali, gegn því að undirrita strangar trúnaðarskuldbindingar um meðferð kjörskrárinnar, að meðferð hennar samræmist í einu og öllu reglum flokksins og lögum landsins. Við gerð kjörskrár eru netföng viðkomandi aldrei afhent þriðja aðila, en einstaka frambjóðendum gefst í prófkjörum kostur á að biðja skrifstofu flokksins um að senda út tölvupóst í sínu nafni á þá aðila sem eru á kjörskrá.</p>

        <h3>6. MIÐLUN PERSÓNUUPPLÝSINGA</h3>
        <p>Sjálfstæðisflokkurinn deilir ekki persónuupplýsingum með þriðja aðila nema vegna skyldu samkvæmt lögum, fyrirmælum stjórnvalda eða dómstóla, til að geta haldið uppi vörnum vegna réttarágreinings, í samræmi við ákvæði samninga eða að undangengnu samþykki. Flokknum er heimilt að miðla persónuupplýsingum til vinnsluaðila sem er þjónustuveitandi eða verktaka á vegum flokksins, sbr. 25. gr. laga nr. 90/2018 um persónuvernd og vinnslu persónuupplýsinga. Á það m.a. við um þá aðila sem halda utan um örugga geymslu gagna flokksins, veita kerfislausnir varðandi flokksskrá og bókhald, aðila sem veita flokknum upplýsingatækniþjónustu og aðra þjónustu sem tengist vinnslu og er hluti af rekstri flokksins og nauðsynleg í þeim tilgangi að hann geti rækt hlutverk sitt og skyldur gagnvart félagsmönnum, starfsmönnum og þeim sem hann á í viðskiptasambandi við. Vinnsluaðili fær einungis aðgang að persónuupplýsingum svo fremi að trúnaður ríki um gögnin, þeim sé eytt að vinnslu lokinni, einungis nýttar í þeim tilgangi sem þeim er miðlað og meðferð þeirra hagað í samræmi við lög og reglur.</p>

        <h3>7. HEIMILD FYRIR ÖFLUN OG VINNSLU PERSÓNUUPPLÝSINGA</h3>
        <p>Heimild flokksins til vinnslu persónuupplýsinga byggir ýmist á samþykki þeirra sem þær hafa veitt, vegna samningssambands eða af nauðsyn vegna lögmætra hagsmuna flokksins af því að geta tryggt áreiðanlega og öfluga þjónustu auk miðlunar upplýsinga um störf og stefnu flokksins.</p>

        <h3>8. UPPLÝSINGAÖRYGGI</h3>
        <p>Sjálfstæðisflokkurinn leggur áherslu á öryggi í meðferð persónuupplýsinga og að koma í veg fyrir óheimilan aðgang að upplýsingum í vörslu flokksins. Í því sambandi leitast flokkurinn við að grípa til viðeigandi tæknilegra og skipulegra ráðstafana til að vernda persónupplýsingar með sérstöku tilliti til eðlis þeirra. Þessum ráðstöfunum er ætlað að vernda persónuupplýsingar gegn því að þær glatist eða breytist fyrir slysni og gegn óleyfilegum aðgangi, afritun, notkun eða miðlum þeirra. </p>
        <p>Sjálfstæðisflokkurinn viðhefur t.a.m. aðgangsstýringu að upplýsingakerfum flokksins í þeim tilgangi að tryggja framangreint.</p>

        <h3>9. GEYMSLUTÍMI GAGNA</h3>
        <p>Sjálfstæðisflokkurinn varðveitir persónuupplýsingar í þann tíma sem er nauðsynlegur í samræmi við tilgang og markmið vinnslu hverju sinni.</p>

        <h3>10. RÉTTINDI HINNA SKRÁÐU</h3>
        <p>Einstaklingar eiga rétt á því að fá staðfest hvort Sjálfstæðisflokkurinn vinni með persónuupplýsingar um þá eða ekki, fara fram á aðgang að persónuupplýsingum sínum og við ákveðnar aðstæður að láta leiðrétta þær, eyða þeim eða takmarka vinnslu þeirra. Einnig á viðkomandi rétt á að andmæla vinnslu og fara fram á að gögn hans séu flutt. Til að fá framangreindar upplýsingar ber að hafa samband við persónuverndarfulltrúa Sjálfstæðisflokksins á <a href="mailto:personuvernd@xd.is">personuvernd@xd.is</a></p>
        <p>Þá hefur einstaklingur rétt að bera upp spurningar við Persónuvernd um stefnu þessa eða hvernig flokkurinn vinnur með persónuupplýsingar. Enn fremur er hægt að leggja fram kvörtun til Persónuverndar ef eitthvað þykir athugavert við meðferð flokksins á persónuupplýsingum.</p>
        <p>Ef upp koma aðstæður þar sem flokkurinn getur ekki orðið við beiðni um upplýsingar mun flokkurinn leitast við að útskýra hvers vegna svo sé.</p>
        
        <h3>11.	ENDURSKOÐUN</h3>
        <p>Sjálfstæðisflokkurinn getur frá einum tíma til annars breytt persónuverndarstefnu þessari í samræmi við breytingar á viðeigandi lögum eða reglugerðum eða vegna breytinga á því hvernig flokkurinn vinnur með persónuupplýsingar.</p>
        <p>Allar breytingar sem kunna að verða gerðar á stefnunni taka gildi eftir að uppfærð útgáfa hefur verið birt á heimasíðu flokksins.</p>
        <p>Þessi persónuverndarstefna var birt hinn 12. júlí 2018.</p>
        
      </div>
    </div>
  );
}

export default ModalPrivacyPolicy;