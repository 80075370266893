import React from 'react';
import Field from './Field';
import FieldContent from './FieldContent';
import ActionButton from './ActionButton';
import { useAppDispatch, useAppState } from '../store';
import { useTranslation } from 'react-i18next';

/**
 * ActionField component
 * 
 * @param {object} param - destructured props object
 * @param {string} param.name - field name
 * @param {object} param.data - field data
 * @param {string} param.title - field title
 * 
 * @returns {JSX Element}
 */
function ActionField({ name, data, title }) {

  const { content } = data;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user: { sponsor } } = useAppState();

  const modalName = ((fieldName) => {
    switch (fieldName) {
      case 'partyStatus':
        return 'leave';
      case 'communication':
        return 'communicationModal'
      case 'sub_organizations':
        return 'sub_organizations'
      case 'donations':
      case 'sponsorship':
        return 'sponsorship'
      case 'barcode':
        return 'barcode'
      default:
        return null;
    }
  })(name);

  const preparedButtonText = ((fieldName) => {
    switch (fieldName) {
      case 'partyStatus':
        return t('buttons.leaveParty');
      case 'privacyPolicy':
      case 'communication':
        return t('buttons.view');
      case 'sponsorship':
        return t(`buttons.${sponsor ? 'change' : 'sponsor'}`)
      case 'donations':
        return t('buttons.donate')
      case 'barcode':
        return t('buttons.show')
      default:
        return t('buttons.change');
    }
  })(name);

  const modalType = ((fieldName) => {
    switch (fieldName) {
      case 'sponsorship':
      case 'donations':
        return fieldName
      default:
        return null;
    }
  })(name);

  const externalLink = ((fieldName) => {
    switch (fieldName) {
      case 'privacyPolicy':
        return 'https://xd.is/wp-content/uploads/2022/01/Pers%C3%B3nuverndarstefna-Sj%C3%A1lfst%C3%A6%C3%B0isflokksins-140122.pdf'
      case 'sponsorship':
      case 'donations':
        return 'https://xd.is/styrkja/'
      default:
        return null;
    }
  })(name);

  /**
   * Handles action field modal toggle
   */
  const toggleModal = () => {
    dispatch({ type: 'TOGGLE_MODAL', modalName: modalName, modalType })
  };

  return (
    <Field title={title} subtitle={t(`optionalFiledsText.${name}`, '')} additionalClassName={name} >
      <FieldContent content={content} fieldName={name} />
      <ActionButton
        onClick={toggleModal}
        buttonText={preparedButtonText}
        externalLink={externalLink}
      />
    </Field>
  );
}

export default ActionField;