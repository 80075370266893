import React, {useState} from 'react';

import { TOGGLE_MODAL } from '../store/actions';
import { useAppDispatch, useAppState } from '../store';
import { useTranslation } from 'react-i18next';
import { donationOptions, subscriptionOptions } from '../utils/sponsorshipData'

//Component
import ConfirmButton from './ConfirmButton';
// Images
import donationsImg from './images/donations.svg'
import sponsorshipImg from './images/sponsorship.svg'

//Style 
import './ModalSponsorship.scss';


/**
 * Component
 * render sponsorship modal if it is opened
 *
 *  @param {object} param - destructured props object
 *  @param {object} pram.eventData - Object with sponsor modal data
 *  @return {ReactElement}
 */
function ModalSponsorship({ type }) {

  const dispatch = useAppDispatch();
  const { user: { sponsor }} = useAppState();
  const { t } = useTranslation();

  /*
  * Close dialog on click
  */
  const closeModalOnClick = () => dispatch({ type: TOGGLE_MODAL, modalName: 'closeModal'});
  const [ selectedOption, setSelectedOption ] = useState({
    amount: "",
    link: ""
  }) 

  const options = type === 'donations' ? donationOptions : subscriptionOptions;

  /**
   * Handles payment option select/deselect 
   * 
   * @param {object} option 
   * @returns {function}
   */
  const selectOption = option => e => {
    const amount = e.target.value;

    if(amount !== selectedOption.amount) {
      // if not selected, select
      setSelectedOption({
        amount: option.amount, 
        link: option.link
      })
    } else {
      // if selected, deselect
      setSelectedOption({
        amount: "", 
        link: ""
      })
    }
  }

  /***
   * Handles redirect to payment provider
   */
  const redirectToPayment = () => {
    window.open(selectedOption.link, "__blank");
    closeModalOnClick()
  }


  return (
    <>
     <div className="modal-sponsorship">

        {/* Modal header start */}
        <div className="modal-sponsorship__header">
          <span className="modal-sponsorship__header-image">
            <img src={type === 'donations' ? donationsImg : sponsorshipImg} alt="Modal logo"/>
          </span>
           <span className="modal-sponsorship__header-text">{t(`modals.${type}.modalName`)}</span>
          <button 
            className="modal-sponsorship__header-close"
            onClick={closeModalOnClick}
          >
          </button>
        </div>
        {/* Modal header end */}

        {/* Modal body start*/}
        <div className="modal-sponsorship__body">

          { type === 'donations' ? (
            <div className="modal-sponsorship__body-text-section">
              <h3 className="modal-sponsorship__body-text-section-title">{t(`modals.${type}.textSection.title`)}</h3>
              <p className="modal-sponsorship__body-text-section-text">{t(`modals.${type}.textSection.text`)}</p>
            </div>
          ) : (
            <div className={`modal-sponsorship__body-text-section ${sponsor ? "modal-sponsorship__body-text-section--sponsor" : ""}`}>
              <h3 className="modal-sponsorship__body-text-section-title">{t(`modals.${type}.textSection.${sponsor ? 'titleActive' : 'titleInactive'}`)}</h3>
              <p className="modal-sponsorship__body-text-section-text">{t(`modals.${type}.textSection.${sponsor ? 'textActive' : 'textInactive'}`)} {sponsor && <a href="mailto:xd@xd.is">xd@xd.is</a>}</p>
            </div>
          ) }

          { (type === 'donations' || !sponsor) && (
            <>
            <div className="modal-sponsorship__body-options-section">
            <h4 className="modal-sponsorship__body-options-section-title">{t(`modals.${type}.optionsSection.title`)}</h4>
            <p className="modal-sponsorship__body-options-section-text">{t(`modals.${type}.optionsSection.text`)}</p>
            <ul className="modal-sponsorship__body-options-section-options">
              {
                options.map( (option, index) => {
                  return (
                    <li className="modal-sponsorship__body-options-section-options-option" key={index}>
                      <input 
                        type="button"
                        onClick={selectOption(option)}
                        className={selectedOption.amount === option.amount ? 'selected' : ''}
                        value={option.amount}
                      />
                    </li>
                  )
                })
              }
            </ul>
            <p className="modal-sponsorship__body-options-section-disclaimer">
              {t(`modals.${type}.optionsSection.disclaimer`)} <a href="mailto:xd@xd.is">xd@xd.is</a>
            </p>
          </div>

            <ConfirmButton 
              onClick={redirectToPayment}
              className={selectedOption.amount ? "confirm-button--can-submit" : ""}
            >
              {t(`modals.${type}.mainButtonText`)}
            </ConfirmButton>
            </>
          )}
          

         
        </div>
        {/* Modal body end*/}
      </div>
    </>
  )
}

export default ModalSponsorship;