import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useAppState, useAppDispatch } from '../store';
import { TOGGLE_SIDEBAR } from '../store/actions';

import SecondaryNavigation from './SecondaryNavigation';
//translations 
import { useTranslation } from "react-i18next";

import Logo from './images/logo.svg';
import avatar from './images/avatar.png';

//Style 
import './_header.scss'
function Header(props) {

  const dispatch = useAppDispatch();
  const { user, openSidebar } = useAppState();
  const userProfile = user.profile;
  const userProfileImage = userProfile.profile_image.content.value;
  const termsAccepted = user.accepted_terms;

  //Translation hooks 
  const { t, i18n } = useTranslation();
  let currentLang = i18n.language;
  //Local state
  const [isNaviVisible, setIsNaviVisile] = useState(false);
  //User profile image style
  const profileImageStyle = {
    backgroundImage: `url(${userProfileImage ? userProfileImage : avatar})`,
  }
  const toggleSidebar = () => dispatch({ type: TOGGLE_SIDEBAR })
  const toggleNavigation = () => setIsNaviVisile(!isNaviVisible);
  const toggleLang = (lng) => () => i18n.changeLanguage(lng);

  return (
    <div className="header">
      <div className="header__wrapper">
        <div className="header__logo" >
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        {
          termsAccepted && (
            <div className={openSidebar ? 'header__events header__events--active' : 'header__events'} onClick={toggleSidebar}>
              {t('announcements')}
            </div>
          )
        }
        <div className="header__profile-wrapper" onClick={toggleNavigation}>
          <span>{user.profile.full_name.content.value}</span>
          <div className="header__profile-image-wrapper">
            <div className="header__profile" style={profileImageStyle} ></div>
          </div>

          <SecondaryNavigation isVisible={isNaviVisible} currentLang={currentLang} toggleLang={toggleLang(currentLang === 'en' ? 'is' : 'en')} userProfile={{ ...userProfile }} />
        </div>
      </div>
    </div>
  );
}

export default Header;