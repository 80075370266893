import React, { useState } from 'react'
import ErrorMessage from './ErrorMessage';
import InputField from './InputField';
import RadioOrCheckInput from './RadioOrCheckInput';
import ConfirmButton from './ConfirmButton';
import warning from './images/c-warning.svg';
import { useTranslation } from "react-i18next";
import TermsAndConditionsText from './TermsAndConditionsText';
import { useAppApi } from '../store';
import { useAppDispatch } from '../store';



import './TermsForm.scss'



export default function TermsForm({ redirectToProfile }) {

  const { t } = useTranslation();
  const { updateMiddleware, showErrorPopup, hideErrorPopup, fetchUserData } = useAppApi();
  const dispatch = useAppDispatch();



  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [terms, setTerms] = useState(false)
  const [showLoader, setShowLoader] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const validatePhoneNumber = phoneNumber => {
    const regEx = /^[0-9+\-/\s]*$/;
    return regEx.test(phoneNumber)
  }

  const validateEmail = email => {
    const regEx = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return regEx.test(email)
  }


  const checkIfRedyForSubmitt = () => {
    const phoneReady = phoneNumber !== "" && validatePhoneNumber(phoneNumber)
    const emailReady = email !== "" && validateEmail(email)
    return phoneReady && emailReady && terms
  }


  const preparedTermsPageData = {
    profile: {
      email: {
        content: {
          value: email
        }
      },
      phone_number: {
        content: {
          value: phoneNumber
        }
      }
    },
    accepted_terms: terms
  }


  const finishSubmit = () => {
    hideErrorPopup()
    setShowLoader(true)
    updateMiddleware(preparedTermsPageData)
      .then(response => {
        if (response && response.ok) {
          return fetchUserData('/me')
        } else {
          showErrorPopup('network')
          setShowLoader(false)
        }
      })
      .then(response => {
        if (!response.ok) {
          showErrorPopup('network')
          return;
        }
        return response.json()
      })
      .then(data => {
        // add user data to local state
        dispatch({ type: 'ADD_USER_DATA', payload: data.member.data })
        // redirect to 'profile' route and sand data to triger welcome modal
        redirectToProfile({ showWelcomeModal: true })
      })
      .catch(err => {
        console.log(err)
        showErrorPopup('network')
        setShowLoader(false)
      })
  }

  const handleSubmitt = () => {
    setIsFormSubmitted(true)
    if (checkIfRedyForSubmitt()) {
      finishSubmit()
    }
  }

  return (
    <div className='terms-form'>
      <p className='terms-form__info'>{t('termsPageTranslate.formText')}</p>

      <div className={phoneNumber !== '' && isFormSubmitted
        ? 'sign-up__field-wrapper sign-up__field-wrapper--error'
        : 'sign-up__field-wrapper'}
      >
        <InputField
          value={phoneNumber}
          className={null}
          text={t(`registerForm.phone_number`)}
          isReadOnly={false}
          name={"phone_number"}
          type={"number"}
          isRequired={true}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {(isFormSubmitted && phoneNumber === '') && <ErrorMessage canBeInvalidValue={true} hasInputValidation={false} />}
        {(isFormSubmitted && phoneNumber !== '' && !validatePhoneNumber(phoneNumber)) && <ErrorMessage canBeInvalidValue={true} hasInputValidation={true} />}
      </div>

      <div className={email !== '' && isFormSubmitted
        ? 'sign-up__field-wrapper sign-up__field-wrapper--error'
        : 'sign-up__field-wrapper'}
      >
        <InputField
          value={email}
          className={null}
          text={t(`registerForm.email`)}
          isReadOnly={false}
          name={"email"}
          type={"email"}
          isRequired={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        {(isFormSubmitted && email === '') && <ErrorMessage canBeInvalidValue={true} hasInputValidation={false} />}
        {(isFormSubmitted && email !== '' && !validateEmail(email)) && <ErrorMessage canBeInvalidValue={true} hasInputValidation={true} />}
      </div>

      <div className={`terms-and-conditions-text-wrapper ${!terms && isFormSubmitted ? 'sign-up__radio-wrapp-error' : ''}`}>
        <div className='terms-and-conditions-text-wrapper__child'>
          <RadioOrCheckInput
            type={'checkbox'}
            value={true}
            name={'accepted_terms'}
            onInputChange={e => setTerms(e.target.checked)}
            className='terms-and-conditions-checkbox'
          />
          <TermsAndConditionsText />
        </div>
        {isFormSubmitted && !terms && <ErrorMessage />}
      </div>

      {isFormSubmitted && (phoneNumber === '' || email === '' || !terms) && (
        <div className="sign-up__warning">
          <img src={warning} alt="warning" />
          <span>{t('registerForm.warning')}</span>
        </div>
      )}
      <ConfirmButton onClick={handleSubmitt} showLoader={showLoader} className='modal-form__submit'> {t('termsPageTranslate.finishButton')} </ConfirmButton>
    </div>
  )
}
