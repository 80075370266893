import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from './images/logo.svg';

import { ReactComponent as LangIcon } from '../components/images/world-lang.svg';
import { ReactComponent as PrivacyPolicyIcon } from './images/single-folded-content.svg';

import './RegisterHeader.scss';

export default function RegisterHeader({ currentLang, toggleLang }) {

  const { t } = useTranslation();

  return (
    <div className="register-navbar">
      <div className="register-navbar__overlay"></div>
      <Link to="/" className="register-navbar__logo">
        <img src={Logo} alt="logo" />
      </Link>
      <div className="register-navbar__links">
        <a
          className="register-navbar__links-privacy"
          href="https://xd.is/wp-content/uploads/2022/01/Pers%C3%B3nuverndarstefna-Sj%C3%A1lfst%C3%A6%C3%B0isflokksins-140122.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <PrivacyPolicyIcon /> {t('privacyPolicy')}
        </a>
        <div className='register-navbar__lang' onClick={toggleLang(currentLang === 'en' ? 'is' : 'en')}>
          <LangIcon /><span>{currentLang === 'en' ? "Icelandic" : "English"}</span>
        </div>
      </div>
    </div>
  )
}
