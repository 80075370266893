import React from 'react'
import { useTranslation } from "react-i18next";
//Styles
import './ErrorMessage.scss'

/**
 * This is error message for input fields
 * This field is position absolute, so it will be positioned depeanding of parrent element
 * Two messages are available, depeanding of hasInputValidation prop it will return message
 * @param {hasInputValidation} param -> Text of the message depeands of param => param === true ? Please enter a valid input here : This field is required : 
 */
export default function ErrorMessage({ hasInputValidation = false }) {

  const { t } = useTranslation();
  const errorMessage = (inputMessage) => <div className='registration-error'>{t(inputMessage)}</div>

  return (
    hasInputValidation
      ? errorMessage('validInput')
      : errorMessage('requiredField')
  )

}


