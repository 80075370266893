import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ModalWrapper from './ModalWrapper';
import SignUpForm from './SignUpForm';
import RegisterHeader from './RegisterHeader';

import { useAppState } from '../store';

import { ReactComponent as PrivacyPolicyIcon } from './images/single-folded-content.svg';

import './RegisterForm.scss';


/**
 * Register Component
 */
function Register(props) {

  const { t, i18n } = useTranslation();
  let currentLang = i18n.language;
  const currentState = useAppState()
  const termsAccepted = currentState.user.accepted_terms;

  /**
   * Toggles language
   * 
   * @param {string} lng 
   */
  const toggleLang = (lng) => () => i18n.changeLanguage(lng);

  /**
   * Redirect to '/profile' page with provided state
   * @param {*} state 
   */
  const redirectToProfile = state => props.history.push({ pathname: '/profile', state })

  const { location } = props

  useEffect(() => {
    if (!location.state || termsAccepted) props.history.push({ pathname: '/', })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (location.state && location.state.data) {
    return (
      <div className="register-page">
        <RegisterHeader currentLang={currentLang} toggleLang={toggleLang} />
        <div className="register-page__wrapper">
          <div className="register-page__content">
            <h1>{t('registerForm.title')}</h1>
            <p>{t('registerForm.subTitle')}</p>
          </div>
          <SignUpForm userData={location.state.data} lang={currentLang} redirectToProfile={redirectToProfile} />
        </div>
        <a
          className="register-page__footer"
          href="https://xd.is/wp-content/uploads/2022/01/Pers%C3%B3nuverndarstefna-Sj%C3%A1lfst%C3%A6%C3%B0isflokksins-140122.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <PrivacyPolicyIcon /> <p>{t('privacyPolicy')}</p>
        </a>
        <ModalWrapper />
      </div>
    );
  }
  return null
}

export default Register;