import React, { useState } from 'react';
import ModalInnerDialog from './ModalInnerDialog';
import ConfirmButton from './ConfirmButton';
import CancelButton from './CancelButton';
import RadioOrCheckInput from './RadioOrCheckInput';
import { useAppDispatch, useAppState, useAppApi } from '../store';
import './ModalGeneralSubOrganizations.scss';
import './EditCheckBoxList.scss';

import { useTranslation } from 'react-i18next';

/**
 * ModalLeaveParty component
 * 
 * @returns {JSX Element}
 */
function ModalGeneralSubOrganizations() {

  const { t, i18n } = useTranslation();
  let currentLang = i18n.language;

  const dispatch = useAppDispatch();

  const { updateMiddlewareOnSaveChange } = useAppApi();
  const { showErrorPopup, hideErrorPopup } = useAppApi();


  const { user } = useAppState();

  const [subOranizations, setSubOrganizations] = useState(user.party.sub_organizations_general.content.value)
  const [showLoader, setShowLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [saveEnabled, setSaveEnabled] = useState(false);

  /**
   * Handles input change
   * 
   * @param {event} e 
   */
  const onInputChange = (e) => {
    let preparedOptions = subOranizations.map(element => {
      const optionName = currentLang === 'is' ? element.name : element.name_en;
      if (optionName === e.target.value) {
        element = { ...element, checked: e.target.checked }
      }
      return element;
    });

    setSubOrganizations(preparedOptions)

    if (!saveEnabled) {
      setSaveEnabled(true);
    }
  };

  /**
   * Handles save change
   */
  const onChangeSave = () => {
    setShowLoader(true)
    hideErrorPopup()
    updateMiddlewareOnSaveChange('party', 'sub_organizations_general')(subOranizations)
      .then(response => {
        if (response && response.ok) {
          setShowConfirmationModal(true)
          dispatch({
            type: 'CHANGE_PARTY_INFO',
            payload: { fieldName: 'sub_organizations_general', value: subOranizations }
          })
        } else {
          showErrorPopup('network')
        }
        setShowLoader(false)
      })
      .catch(err => {
        console.log(err)
        setShowLoader(false)
        showErrorPopup('network')
      })
  }


  /**
   * Handles modal close
   */
  const closeModal = () => {
    dispatch({ type: 'TOGGLE_MODAL', modalName: null })
    hideErrorPopup()
  };

  if (!showConfirmationModal) {
    return (
      <>
        {
          <div className="change-organization">

            {/* Modal header start */}
            <div className="change-organization__header">
              <span className="change-organization__header-image">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22">
                  <g fill="#00ADEF" fillRule="nonzero">
                    <ellipse cx="4.143" cy="4.258" rx="2.071" ry="2.129" />
                    <path d="M4.833 17.742v-7.097c0-1.05.32-2.022.863-2.826-.057-.003-.114-.013-.172-.013H2.762C1.239 7.806 0 9.08 0 10.646v4.257h1.381v4.968h4.833v-2.13h-1.38zM12.429 7.097H9.667c-1.904 0-3.453 1.592-3.453 3.548v5.678h2.072V22h5.524v-5.677h2.071v-5.678c0-1.956-1.549-3.548-3.452-3.548z" />
                    <ellipse cx="11.048" cy="2.839" rx="2.762" ry="2.839" />
                  </g>
                </svg>
              </span>
              <span className="change-organization__header-text">{t('modals.changeSubOrganizations.modalName')}</span>
              <button className="change-organization__header-close" onClick={closeModal}></button>
            </div>
            {/* Modal header end */}

            {/* Modal body start */}
            <div className="change-organization__body">
              <h3 className="change-organization__body-title">{t('modals.changeSubOrganizations.title')}</h3>
              <p className="change-organization__body-text">{t('modals.changeSubOrganizations.text')}</p>
              <div className="change-organization__options edit-checkbox">
                <ul className="change-organization__options-list edit-checkbox__list">
                  {
                    subOranizations.map((item, index) => {
                      return (
                        <RadioOrCheckInput
                          key={index}
                          type='checkbox'
                          name='edit-checkbox-item'
                          value={currentLang === 'is' ? item.name : item.name_en}
                          checked={item.checked}
                          onInputChange={onInputChange}
                          className="edit-checkbox__list-item"
                        />
                      )
                    })
                  }
                </ul>
              </div>

              <p className="change-organization__body-warning">{t('modals.changeSubOrganizations.disclaimer')}</p>

              <div className={`change-organization__body-buttons ${saveEnabled ? 'change-organization__body-buttons--can-submit' : ''}`}>
                <ConfirmButton
                  className="confirm-button"
                  onClick={onChangeSave}
                  showLoader={showLoader}
                >
                  {t('modals.changeSubOrganizations.mainButtonText')}</ConfirmButton>
                <CancelButton onClick={closeModal} />
              </div>

            </div>
            {/* Modal body end */}
          </div>
        }
      </>
    );
  }
  return <ModalInnerDialog
    image="success"
    handle="changeSubOrganizationsConfirm"
    textAsNote={true}
    close={() => dispatch({ type: 'TOGGLE_MODAL', modalName: null })}
  />

}

export default ModalGeneralSubOrganizations;