import React, { useEffect } from 'react';
import Wrapper from './Wrapper';
import { useAppState, useAppApi, useAppDispatch } from '../store';
import SectionLabels from './SectionLabels';
import { useTranslation } from "react-i18next";
/**
 * Profile component
 * 
 * @param {props} props 
 * @returns {JSX Element}
 */
function Profile(props) {

  const dispatch = useAppDispatch();
  const { user } = useAppState();
  const labels = SectionLabels('profileContent');
  const { getSelectedLanguage } = useAppApi();
  const lang = getSelectedLanguage(user)
  const { i18n } = useTranslation();
  const { history, location } = props;


  useEffect(() => {
    if(history && location && location.state && location.state.showWelcomeModal) {
      // display welcome modal
      dispatch({ type: 'TOGGLE_MODAL', modalName: 'welcome'})
      // reset history.state so modal wouldn't be displayed on page reload
      history.replace('/profile', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    i18n.changeLanguage(lang)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  return <Wrapper labels={labels} data={user.profile} screen="profile" />;
}

export default Profile;