import React, { useState } from 'react';
import './_sidebar.scss';
import './_modal.scss';
import { useAppState, useAppDispatch } from '../store';
import { useTranslation } from 'react-i18next';
import { TOGGLE_SIDEBAR, TOGGLE_MODAL, CLOSE_SIDEBAR } from '../store/actions';
import eventIcon from './images/events-icon.png';
//Components 
import NoEvents from './NoEvents';
import becomeSponsorIS from './images/is_sponsor.png'
import becomeSponsorEN from './images/en_sponsor.png'

function Sidebar(props) {

  const { openSidebar, eventState } = useAppState();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  //Function expression
  const handleOnClick = (eventType, modalType = null) => e => dispatch({ type: TOGGLE_MODAL, modalName: eventType, modalType });
  const toggleSidebar = () => dispatch({ type: TOGGLE_SIDEBAR });

  // Sidebar state for swipe 
  const [sidebarState, setSidebarState] = useState({
    dir: null,
    dist: 0,
    startX: 0,
    distX: 0,
    minDragXRoute: 125, //required min distance traveled to be considered swipe
  });
  //This is object which containst all functions related to touch event for sidebar 
  const swipeActions = {
    onTouchMove: function (e) {
      let touchobj = e.changedTouches[0];
      setSidebarState({
        ...sidebarState,
        distX: touchobj.pageX - sidebarState.startX,
        dir: sidebarState.distX < 0 ? 'left' : 'right',
      })
    },
    onTouchStart: function (e) {
      let touchobj = e.changedTouches[0];
      setSidebarState({
        ...sidebarState,
        dir: 'none',
        dist: 0,
        startX: touchobj.pageX,
      })
    },
    onTouchEnd: function (e) {
      if (sidebarState.distX > sidebarState.minDragXRoute && sidebarState.dir === 'right') {
        dispatch({ type: CLOSE_SIDEBAR });
        swipeActions.setStateToDefault();
      }
    },
    setStateToDefault() {
      setSidebarState({
        dir: null,
        dist: 0,
        startX: 0,
        distX: 0,
        minDragXRoute: 125
      })
    }
  }
  return (
    <div onTouchStart={swipeActions.onTouchStart} onTouchMove={swipeActions.onTouchMove} onTouchEnd={swipeActions.onTouchEnd}
      className={`${openSidebar ? 'sidebar sidebar-mobile--visible' : 'sidebar sidebar-mobile--hidden'} `}>
      <div className="sidebar__head">
        <img className="sidebar__events-icon" src={eventIcon} alt="event-icon" />
        <p>{t('announcements')}</p>
        <span className="close-modal" onClick={toggleSidebar}></span>
      </div>
      <div className="sidebar__wrapper">
        {Object.entries(eventState).length !== 0 ?
          Object.entries(eventState).map((event, index) => {
            const [, eventItem] = event;
            const backgroundImageStyle = { backgroundImage: 'url(' + eventItem.image + ')' }

            return (
              <div eventtype={eventItem.type} key={index} className={`sidebar__single-event sidebar__single-event--${eventItem.type}`}>

                {eventItem.type === 'general'
                  ? <div style={backgroundImageStyle} className={`sidebar__single-event--${eventItem.type}-image`} />
                  : <div onClick={eventItem.type === 'sponsorship' ? () => window.open('https://xd.is/styrkja/', '_blank') : handleOnClick(eventItem.type)} style={backgroundImageStyle} className={`sidebar__single-event--${eventItem.type}-image`} >
                    {eventItem.type === 'sponsorship' && (
                      <img
                        className={`sidebar__single-event--${eventItem.type}-image-aditional`}
                        src={i18n.language === 'is' ? becomeSponsorIS : becomeSponsorEN}
                        alt="Become sponsor"
                      />
                    )}
                  </div>}
                {eventItem.title !== '' ? <div className={`sidebar__event-title--${eventItem.type}`}>{eventItem.title}</div> : null}
                {eventItem.contentPreview !== '' ? <div className="sidebar__event-subtitle">{eventItem.contentPreview}</div> : null}
                {eventItem.type === 'general' ? <div onClick={handleOnClick(eventItem.type)} className="sidebar__button" >{t('runForOffice')}</div> : null}

              </div>
            )
          }) : <NoEvents />
        }

      </div>
    </div>
  );
}

export default Sidebar;