import React from 'react'
import IdleTimer from 'react-idle-timer';
import { useAppDispatch, useAppState } from '../store';

/**
 * ActivityTracker component
 * Track user activity and sets automatic logout in motion aftr prolonged period of inactivity
 * 
 * @returns {JSX Element}
 */
export default function ActivityTracker() {

  const dispatch = useAppDispatch();
  const { user: { logged_out } } = useAppState();
  let idleTimer = null;

  /**
   *  Triggers SessionAboutToExpire modal after IdleTimer's timout 
   */
  function handleOnIdle () {
    if(!logged_out) {
      dispatch( {type: 'TOGGLE_MODAL', modalName: 'session_expired'})
    }
  }

  return <IdleTimer
    ref={ref => { idleTimer = ref }}
    element={document}
    onIdle={handleOnIdle}
    timeout={60 * 30 * 1000} 
  />
}
