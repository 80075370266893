import React, { useEffect } from 'react';
import Header from './Header';
import ModalWrapper from './ModalWrapper';
import TermsForm from './TermsForm'

import { useTranslation } from 'react-i18next';

import Logo from './images/logo.svg';
import { ReactComponent as LangIcon } from '../components/images/world-lang.svg';
import './Terms.scss';

import { useAppState, useAppDispatch, useAppApi } from '../store';
import LeavePartyTerms from './LeavePartyTerms';




/**
 * Terms & Conditions component
 * 
 * @param {props} props 
 * @returns {JSX Element}
 */
function Terms(props) {
  const { user } = useAppState();
  const userName = user.profile.full_name.content.value;
  const termsAccepted = user.accepted_terms

  const { fetchUserData } = useAppApi();
  const dispatch = useAppDispatch();


  const { t, i18n } = useTranslation();
  let currentLang = i18n.language;

  /**
   * Toggles language
   * 
   * @param {string} lng 
   */
  const toggleLang = (lng) => () => i18n.changeLanguage(lng);
  const redirectToProfile = state => props.history.push({ pathname: '/profile', state })

  useEffect(() => {
    if (!userName || termsAccepted) {
      props.history.push({
        pathname: '/',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!termsAccepted) {
      dispatch({ type: "SHOW_LOADER", payload: { show: true } });

      fetchUserData('/me')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.statusText}`)
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          dispatch({ type: 'ADD_USER_DATA', payload: data.member.data })
          dispatch({ type: "SHOW_LOADER", payload: { show: false } });
        }
      })
      .catch(err => {
        console.log(err)
        // redirect user to xd.is
        window.location.href = 'https://xd.is/';
      })
    }
    
  }, [])

  console.log('Terms component');

  return (
    <div className="terms">
      <Header />
      <div className="terms__wrapper">
        <div className="terms__header">
          <div className="terms__language">
            <p className="terms__language-text">{t('termsPageTranslate.languageSwitcherText')}</p>
            <div className='terms__language-switcher' onClick={toggleLang(currentLang === 'en' ? 'is' : 'en')}>
              <LangIcon /><span>{currentLang === 'en' ? "Íslenska" : "English"}</span>
            </div>
          </div>
          <div className="terms__logo" >
            <img src={Logo} alt="logo" />
          </div>

          <h1 className="terms__title">{t('termsPageTranslate.welcomeTitle')}</h1>
          {/* <h1 className="terms__title">My space on the Independence Party website</h1> */}
          {/* <p className="terms__subtext">{t('termsPageTranslate.welcomeSubtext')}</p> */}
          <div className="terms__subtext">
            <p>{t('termsPageTranslate.welcomeSubtextFirstPart')}</p>
            <p>{t('termsPageTranslate.welcomeSubtextSecondPart')}</p>
          </div>
          
          <h2 className="terms__subtitle">{t('termsPageTranslate.welcomeSubtitle')}</h2>
        </div>
        <TermsForm redirectToProfile={redirectToProfile} />
        <LeavePartyTerms />
        <ModalWrapper />

      </div>
    </div>
  );
}

export default Terms;