import React from 'react';
import Image from './Image';
import Text from './Text';
import List from './List';
import CheckBoxList from './CheckBoxList';
import RadioButtonValue from './RadioButtonValue';
import './FieldContent.scss';

/**
 * FiedlContent component
 * 
 * @param {object} param - destructured props object
 * @param {object} param.content - field content
 * @param {object} param.content - field content
 * 
 * @returns {JSX Element}
 */
const FieldContent = ({ content, fieldName }) => {
  return (
    <div className="field-content">
      {(() => {
        switch (content.type) {
          case 'image':
            return <Image value={content.value} />
          case 'single':
            return <Text value={content.value} value_en={content.value_en} fieldName={fieldName}/>
          case 'list':
            return <List value={content.value} />
          case 'checkBox':
            return <CheckBoxList value={content.value} />
          case 'radioButton':
            return <RadioButtonValue value={content.value} />
          default:
            return null;
        }
      })()}
    </div>
  )

}

export default FieldContent;