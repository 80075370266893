import React from 'react';
import { ReactComponent as NoEventsIcon } from './images/no-events.svg';
import './_no-events.scss';

function NoEvents() {
  return (
    <div className="no-events">
      <NoEventsIcon />
      <p>
        There are currently no new events
      </p>
    </div>
  )
}

export default NoEvents