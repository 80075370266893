import React from 'react'
import { useTranslation } from "react-i18next";

/**
 * InputField component
 * 
 * @param {object} param - destructured props object
 * @param {string} param.value - field value
 * @param {string} param.content - Text above the field 
 * @param {string} param.className - specific class name ( usualy used for validation ) 
 * @param {string} param.type - input type
 * @param {string} param.name - input name
 * @param {boolean} param.isRequired - is required field in form ( defautl value === true )
 * @param {boolean} param.isReadOnly - is field editable ( default value === false )
 * @param {function} param.onChange - handles on change event
 * 
 * @returns {JSX Element}
 */
export default function InputField({ value, text, className, type, name, isRequired = true, isReady, onChange, isReadOnly = false, isFormSubmited }) {
  const { t } = useTranslation();

  return (
    <div className={`modal-form__input-wrapper ${className}`}>
      <div className="modal-form__input-title">
        <span>{text}</span>
        {isRequired ? null : <span className="modal-form__input-optional"> {t('optional')} </span>}
      </div>

      <input onChange={onChange} onWheel={(e) => e.target.blur()} className='modal-form__form-input' value={value} type={type} name={name} required={isRequired ? false : true} readOnly={isReadOnly} />
    </div>
  )
}
