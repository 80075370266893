import React, {useState} from 'react';
import ModalInnerDialog from './ModalInnerDialog';
import ConfirmButton from './ConfirmButton';
import CancelButton from './CancelButton';
import RadioOrCheckInput from './RadioOrCheckInput';
import { useAppDispatch, useAppApi, useAppState } from '../store';
import './ModalLeaveParty.scss';
import { useTranslation } from 'react-i18next';

/**
 * ModalLeaveParty component
 * 
 * @returns {JSX Element}
 */
function ModalLeaveParty() {

  const [ selectedOption, setSelectedOption ] = useState('');
  const [ canSubmit, setCanSubmit ] = useState(false);
  const [ textareaValue, setTextareaValue ] = useState('');
  const [ showConfirmLeavingModal, setShowConfirmLeavingModal ] = useState(false);
  const [ showLeftSuccessfullyModal, setShowLeftSuccessfullyModal ] = useState(false);
  const [ showMainModal, setShowMainModal ] = useState(true);
  const [ showLoader, setShowLoader ] = useState(false);

  const { t, i18n } = useTranslation();
  const modalDispatch = useAppDispatch();
  const { deleteUser, showErrorPopup, hideErrorPopup } = useAppApi();

  const { 
    user: { 
      profile: { ssn: {content: { value: ssn}} },  
      reasons_for_leaving: { content: { value: options}}
    } 
  } = useAppState();

  const lang = i18n.language;

  /**
   * Handles input change
   * 
   * @param {event} e 
   */
  const onInputChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);

    if(value !== 'Other' && value !== 'Annað') {
      // for all options except 'Other' enable submit button
      if(!canSubmit) {
        setCanSubmit(true);
      }
      // if user typed something into text area and than choose another option
      // set textarea value to an empty string
      if(textareaValue !== '') {
        setTextareaValue('')
      }
    // if submit button is enabled and user choose 'Other' option, disable submit button
    } else if((value === 'Other' || value === 'Annað') && canSubmit) {
      setCanSubmit(false)
    }
  }

  /**
   * Handle text area change
   * 
   * @param {event} e 
   */
  const onTextareaChange = (e) => {
    const value = e.target.value;
    setTextareaValue(value)

    // if textarea has at least one character in it, enable submit button else disable it
    if(value.length > 0) {
      if(!canSubmit) {
        setCanSubmit(true);
      }
    } else {
      setCanSubmit(false);
    }
  }

  /**
   * Prepares data for updating middleware
   * 
   * @param {array} options 
   * @param {string} selected 
   * @returns {object}
   */
  const prepareData = ( options, selected ) => {
    const filteredOptions = options.filter( option => option.name === selected || option.name_en === selected)

    if(filteredOptions.length > 0) {
      return {
        ssn,
        reason_id: filteredOptions[0].id,
        ...(!!textareaValue && { note: textareaValue})
      }
    }    
  }

  /**
   * Handles modal close
   */
  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null});

  /**
   * Leave party function should send selected option or custom message, change member party status and show success modal dialog
   */
  const leaveParty = () => {
    hideErrorPopup();
    setShowLoader(true)
    deleteUser(prepareData(options, selectedOption))
    .then( response => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }
      setShowConfirmLeavingModal(false);
      setShowLeftSuccessfullyModal(true);
      setShowMainModal(false);
      setShowLoader(false)
    })
    .catch( e => {
      console.log(e)
      showErrorPopup('network')
      setShowLoader(false)
    })
  }

  
  return (
    <>
      {showMainModal && 
        <div className="leave-modal">

          {/* Modal header start */}
          <div className="leave-modal__header">
            <span className="leave-modal__header-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22">
                  <g fill="#00ADEF" fillRule="nonzero">
                      <ellipse cx="4.143" cy="4.258" rx="2.071" ry="2.129"/>
                      <path d="M4.833 17.742v-7.097c0-1.05.32-2.022.863-2.826-.057-.003-.114-.013-.172-.013H2.762C1.239 7.806 0 9.08 0 10.646v4.257h1.381v4.968h4.833v-2.13h-1.38zM12.429 7.097H9.667c-1.904 0-3.453 1.592-3.453 3.548v5.678h2.072V22h5.524v-5.677h2.071v-5.678c0-1.956-1.549-3.548-3.452-3.548z"/>
                      <ellipse cx="11.048" cy="2.839" rx="2.762" ry="2.839"/>
                  </g>
              </svg>
            </span>
            <span className="leave-modal__header-text">{t('modals.leavingTheParty.modalName')}</span>
            <button className="leave-modal__header-close" onClick={closeModal}></button>
          </div>
          {/* Modal header end */}

          {/* Modal body start */}
          <div className="leave-modal__body">

            <h3 className="leave-modal__body-title">{t('modals.leavingTheParty.title')}</h3>
            <p className="leave-modal__body-text">{t('modals.leavingTheParty.text')}</p>
           
            <div className="leave-modal__body-options">
              
              { options.map((option, index) =>
                  <RadioOrCheckInput
                    key={index}
                    type='radio'
                    name='option'
                    value={lang === 'is' ? option.name : option.name_en}
                    checked={option.name === selectedOption || option.name_en === selectedOption}
                    onInputChange={onInputChange}
                    className="leave-modal__body-options-option"
                  />
              ) }

            </div>

            { (selectedOption === 'Other' || selectedOption === 'Annað') && 
              <textarea
                value={textareaValue}
                placeholder={t('modals.leavingTheParty.placeholderText')}
                onChange={onTextareaChange}
                className="leave-modal__body-text-area"
              />}
            
            <p className="leave-modal__body-warning">{t('modals.leavingTheParty.disclaimer')}</p>

            <div className={`leave-modal__body-buttons ${canSubmit ? 'leave-modal__body-buttons--can-submit': ''}`}>
              <ConfirmButton className="confirm-button" onClick={() => setShowConfirmLeavingModal(true)}>{t('buttons.leaveParty')}</ConfirmButton>
              <CancelButton onClick={closeModal} />
            </div>

          </div>
          {/* Modal body end */}
        </div>
      }
    
      {showConfirmLeavingModal && 
        <ModalInnerDialog 
          image="exclamation"
          handle="confirmLeaving"
          close={() => setShowConfirmLeavingModal(false)} 
          confirm={leaveParty}
          showBtnLoader={showLoader} 
        />}
    
      {showLeftSuccessfullyModal && 
        <ModalInnerDialog 
          image="success"
          handle="leftSuccessfully"
          close={() => window.location = 'https://xd.is/'}
        />}
    </>
  );
}

export default ModalLeaveParty;