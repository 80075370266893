import React, { useRef, useState } from 'react';
import './_modal-form.scss';

import { useAppDispatch } from '../store';
import { TOGGLE_MODAL } from '../store/actions';
import { useTranslation } from 'react-i18next';

import fileBtnImage from './images/add-file.png';
import RadioOrCheckInput from './RadioOrCheckInput';
import InputField from './InputField';
import ConfirmButton from './ConfirmButton';
import ModalInnerDialog from './ModalInnerDialog';

/**
 * Component
 * renders modal form if modal is opened
 *
 * @param {object} props
 * @return {ReactElement}
 */
function ModalForm(props) {

  const { eventData } = props;
  const userProfile = props.userData.profile;
  const dispatch = useAppDispatch();

  //Refs
  const uploadFile = useRef(null);
  const rolesInput = useRef(null);

  // local state
  const [showMainModal, setShowMainModal] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState('');
  const [, setUploadedFile] = useState('');
  const [showRoles, setShowRoles] = useState(false);
  const [roles, setRoles] = useState(
    [
      {
        name: 'Media Strategist',
        checked: false
      },
      {
        name: 'Volunteer',
        checked: false
      },
      {
        name: 'Campaign manager',
        checked: false
      },
      {
        name: 'Legative aide',
        checked: false
      }
    ]
  );
  const { t } = useTranslation();
  /**
   * Close modal on click 
   */
  const closeModalOnClick = (e) => dispatch({ type: TOGGLE_MODAL, modalName: 'closeModal' });

  /**
   * When user select checkbox, certain values will be stored in local state 
   */
  const onInputChange = (e) => {
    let preparedOptions = roles.map(role => {
      if (role.name === e.target.value) {
        role = { ...role, checked: e.target.checked }
      }
      return role;
    });

    setRoles(preparedOptions);
  };

  /**
   * Upload btn has custom style, so this is mask for native html upload btn. And when user click on this element we trigger click on uploadFile( ref )         
   */
  const simulateClick = (element) => element.click();


  //Function statements 
  function submitFrom() {
    setShowMainModal(false);
    setShowSuccessModal(true);
  }


  return (
    <>
      {showMainModal &&
        <form className="modal-form">

          {/* Modal header start */}
          <div className="modal-form__head">
            <p>{eventData.generalEvent.name}</p>
            <span onClick={closeModalOnClick} className="close-modal"></span>
          </div>
          {/* Modal header end */}

          {/* Modal body start */}
          <div className="modal-form__wrapper">
            <div className="modal-form__form-title">
              {eventData.generalEvent.title}
            </div>
            <div className="modal-form__form-content">
              {eventData.generalEvent.textTitle}
            </div>

            <div className="modal-form__input-wrapper">
              <span className="modal-form__span">{t('modals.runningForOffice.inputs.role')}</span>
              <div ref={rolesInput} onClick={() => setShowRoles(showRoles ? false : true)} className="modal-form__role-result modal-form__form-input">
                {roles.map(item => (item.checked) ? item.name + ', ' : '')}
                <span className={showRoles ? "arrow arrow--up" : "arrow arrow--down"}></span>
              </div>
              <div className={showRoles ? 'modal-form__all-roles modal-form__all-roles--expand' : 'modal-form__all-roles'}>
                {roles.map((role, index) =>
                  <RadioOrCheckInput
                    key={index}
                    type="checkbox"
                    value={role.name}
                    checked={role.checked}
                    className="modal-form__role"
                    onInputChange={onInputChange}
                  />
                )}
              </div>
            </div>
            <InputField value={userProfile.ssn.content.value} text={t('modals.runningForOffice.inputs.id')} isReadOnly={true} />
            <InputField value={userProfile.full_name.content.value} text={t('modals.runningForOffice.inputs.name')} isReadOnly={false} />
            <InputField value={userProfile.email.content.value} text={t('modals.runningForOffice.inputs.email')} isReadOnly={true} />
            <div className="modal-form__input-wrapper">
              <span className="modal-form__span">{t('modals.runningForOffice.inputs.message')}</span>
              <textarea name='message' onChange={(e) => setMessage(e.target.value)} placeholder={t('modals.runningForOffice.placeholderText')} />
            </div>
            <div className="modal-form__input-wrapper modal-form__upload-wrapper">
              <div className="modal-form__upload-btn" onClick={() => simulateClick(uploadFile.current)}>
                <img src={fileBtnImage} alt="Upload button" />
                {t('modals.runningForOffice.addButtonText')}
              </div>
              <input ref={uploadFile} onChange={(e) => setUploadedFile(e.target.value)} id="upload-file" type="file" name="file" className="modal-form__upload-hidden-btn" />
              <p className="modal-form__upload-text">
                {t('modals.runningForOffice.fileFormat')}
              </p>
            </div>
            <ConfirmButton className={roles.findIndex((role) => role.checked) >= 0 && message !== '' ? 'modal-form__submit' : 'modal-form__submit inactive-submit'} onClick={submitFrom}>{t('modals.runningForOffice.mainButtonText')}</ConfirmButton>
          </div>
          {/* Modal body end */}
        </form>
      }

      {showSuccessModal &&
        <ModalInnerDialog
          handle="runForOfficeSuccess"
          image="success"
          close={closeModalOnClick}
        />}
    </>
  );
}

export default ModalForm;