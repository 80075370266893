import React from 'react';
import DialogBox from './DialogBox'
import ConfirmButton from './ConfirmButton'
import { useAppDispatch } from '../store';
import welcomeDashboard from './images/welcome_dashbord.svg'

import { useTranslation } from "react-i18next";



const ModalWelcome = () => {

  const modalDispatch = useAppDispatch();
  const { t } = useTranslation();

  /**
   * Handles modal close
   */
  const closeModal = () => modalDispatch({ type: 'TOGGLE_MODAL', modalName: null});

  return (
    <div className="modal-welcome" style={{
      display: "flex", 
      height: "100%", 
      width: "100%",
      justifyContent: "center",
      alignItems: "center"
      }}
    >
     <DialogBox 
        image={<img src={welcomeDashboard} alt="welcome"/>}
        close={closeModal}
        title={t('modals.welcome.title')}
        text={t('modals.welcome.text')}
        renderButtons={() => (
          <ConfirmButton 
            className="confirm-button" 
            onClick={closeModal}
          >
            {t('modals.welcome.mainButtonText')}
          </ConfirmButton>
          )}
     />
    </div>
  );
}

export default ModalWelcome;