import React from 'react';
import './List.scss';
import { useTranslation } from 'react-i18next';


/**
 * List component
 * 
 * @param {object} props 
 * @returns {JSX Element}
 */
function List (props) {

  const { value } = props;
  const { i18n } = useTranslation();
  let lang = i18n.language;

  const list = lang === 'is' ? value.list : value.list_en;
  
  return (
    <ul className="list">
        {
          list.map((item, index ) => item && <li className="list__item" key={index} >{item}</li>)
        }
    </ul>
  )
}

export default List;