import React, { useEffect, useRef } from 'react';
import Field from './Field';
import FieldContent from './FieldContent';
import EditableField from './EditableField';
import ActionField from './ActionField';
import { useAppApi } from '../store';
import './Content.scss';
import { useTranslation } from "react-i18next";

import LeaveParty from './LeaveParty';

/**
 * Content component
 * 
 * @param {object} param - destructured props object
 * @param {object} param.data - field data
 * @param {screen} param.screen - current app screen
 * 
 * @return {JSX Element}
 */
function Content({ data, screen, labels }) {
  const { prepareChangeInfoAction } = useAppApi();
  const preparedDispatch = prepareChangeInfoAction(screen);
  const { t } = useTranslation();

  const container = useRef(null);

  const scrollToTopOfElement = (ref) => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  //If user is at the bottom of the container and change route navigate it at the top of container
  useEffect(() => {
    scrollToTopOfElement(container);
  }, []);

  return (
    <div className="content" ref={container}>
      <div className="content__inner-wrapper">
        {
          Object.entries(data).map((entry, i) => {

            const [fieldName, fieldData] = entry;
            switch (fieldData.type) {
              case 'plain':
                return <Field title={labels[i]} subtitle={t(`optionalFiledsText.${fieldName}`, '')} key={fieldName}>
                  <FieldContent content={fieldData.content} fieldName={fieldName} />
                </Field>
              case 'edit':
                return <EditableField
                  data={fieldData}
                  onEdit={preparedDispatch(fieldName)}
                  fieldName={fieldName}
                  key={fieldName}
                  screen={screen}
                  title={labels[i]}
                  message={t(`editableFieldsMessages.${fieldName}`)}
                />;
              case 'action':
                return <ActionField
                  name={fieldName}
                  data={fieldData}
                  title={labels[i]}
                  key={fieldName}
                />;
              default:
                return null;
            }
          })
        }
      </div>
      {screen === 'profile' && (
        <LeaveParty />
      )}
    </div>
  );
}

export default Content;