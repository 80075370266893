// Polyfills for Internet Explorer 11
import 'react-app-polyfill/ie11';
// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
