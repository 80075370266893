import React, { useState, useEffect } from 'react';
import './Loader.scss';
import Logo from './images/logo.svg';
import LoadingImage from './images/loading-img.png';
import { useAppState } from '../store';

/**
 * Loader component
 * 
 * @returns {JSX Element}
 */
const Loader = () => {
  const currentState = useAppState();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if(currentState.showLoader) {
      setShouldShow(true)
    } else {
      if(shouldShow) {
        setTimeout(() => {
          setShouldShow(false)
        }, 500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.showLoader]);

  if (shouldShow) {
    return (
      <div className="loader">
        <div className="loader__inner-wrapper">
          <img className="loader__logo" src={Logo} alt="loader logo" />
          <img className="loader__loading-img" src={LoadingImage} alt="loading" />
        </div>
      </div>
    );
  }
  return null;
}

export default Loader;